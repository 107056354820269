//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';
import _          from 'lodash';

import Avatar                 from '@/components/stateless/atomic/Avatar';
import AvatarStateCircle      from '@/components/stateless/atomic/AvatarStateCircle';
import AvatarStateCircleTheme from '@/components/stateless/atomic/AvatarStateCircle/AvatarStateCircleTheme';
import CustomButton           from '@/components/stateless/atomic/CustomButton';
import CustomButtonTheme      from '@/components/stateless/atomic/CustomButton/CustomButtonTheme';
import Icon                   from '@/components/stateless/atomic/Icon';
import IconType               from '@/components/stateless/atomic/Icon/IconType';
import Title                  from '@/components/stateless/atomic/Title';
import TitleTheme             from '@/components/stateless/atomic/Title/TitleTheme';
import UserInfo               from '@/components/stateless/atomic/UserInfo';
import CheckInState           from '@/constants/CheckInState';
import PropTypes              from '@components/PropTypes';
import ComponentHelper        from '@helper/ComponentHelper';

import styles from './styles.module.scss';

export default class UserCheckInInfoOverlay extends React.Component {
    static propTypes = {
        address:        PropTypes.string,
        avatarSource:   PropTypes.string,
        checkInState:   PropTypes.oneOfObjectValues(CheckInState),
        customerNumber: PropTypes.string,
        email:          PropTypes.string,
        lastCheckIn:    PropTypes.date,
        onCheckInClick: PropTypes.func,
        onCloseClick:   PropTypes.func,
        subscription:   PropTypes.string,
        time:           PropTypes.string,
        userName:       PropTypes.string,
    };

    static defaultProps = {
        address:        null,
        avatarSource:   null,
        checkInState:   CheckInState.NOT_CHECKED_IN,
        customerNumber: null,
        email:          null,
        lastCheckIn:    null,
        onCheckInClick: _.noop,
        onCloseClick:   _.noop,
        subscription:   null,
        time:           null,
        userName:       null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <div className={styles.userCheckInInfoOverlayContainer}>
                <div className={styles.userCheckInInfoOverlay}>
                    {this.renderCloseButton()}
                    {this.renderTitle()}
                    {this.renderAvatar()}
                    {this.renderCheckInState()}
                    {this.renderUserInfo()}
                    {this.renderCheckInButton()}
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }

    renderCloseButton = () => {
        return (
            <Icon
                onClick={this.props.onCloseClick}
                className={styles.closeButtonIcon}
                iconType={IconType.cross}
            />
        );
    };

    renderTitle = () => {
        const { checkInState, userName } = this.props;

        return (
            <div
                className={classNames(
                    styles.titleRow,
                    {
                        [styles.titleRowMargin]: checkInState === CheckInState.NOT_CHECKED_IN,
                    },
                )}
            >
                <Title
                    text={userName}
                    theme={TitleTheme.white}
                />
            </div>
        );
    };

    renderAvatar = () => {
        const { avatarSource, checkInState } = this.props;
        let avatar                           = (
            <Avatar
                avatarLarge={true}
                source={avatarSource}
            />
        );

        if (
            checkInState === CheckInState.CHECKED_IN ||
            checkInState === CheckInState.CHECK_IN_FAILED
        ) {
            const theme = checkInState === CheckInState.CHECKED_IN ? AvatarStateCircleTheme.green : AvatarStateCircleTheme.red;

            avatar = (
                <AvatarStateCircle theme={theme}>
                    {avatar}
                </AvatarStateCircle>
            );
        }

        return (
            <div
                className={classNames(
                    styles.avatarRow,
                    {
                        [styles.avatarRowMargin]: checkInState === CheckInState.NOT_CHECKED_IN,
                    },
                )}
            >
                {avatar}
            </div>
        );
    };

    renderCheckInState = () => {
        const { checkInState } = this.props;

        return (
            <div
                className={classNames(
                    styles.checkInStateRow,
                    {
                        [styles.checkInStateSuccess]: checkInState === CheckInState.CHECKED_IN,
                        [styles.checkInStateFailed]:  checkInState === CheckInState.CHECK_IN_FAILED,
                    },
                )}
            >
                {I18n.t(checkInState)}
                {this.renderCheckInStateExplanation(checkInState)}
            </div>
        );
    };

    renderCheckInStateExplanation = () => {
        const { checkInState } = this.props;
        let explanation        = null;

        switch (checkInState) {
            case CheckInState.CHECK_IN_FAILED:
                explanation = I18n.t('checkInFailedExplanation');

                break;
        }

        if (explanation) {
            return (
                <span className={styles.checkInStateExplanation}>
                    {explanation}
                </span>
            );
        }

        return null;
    };

    renderUserInfo = () => {
        const { address, customerNumber, email, lastCheckIn, subscription, time } = this.props;

        return (
            <UserInfo
                address={address}
                customerNumber={customerNumber}
                email={email}
                lastCheckIn={lastCheckIn}
                subscription={subscription}
                time={time}
            />
        );
    };

    renderCheckInButton = () => {
        const { onCheckInClick, checkInState } = this.props;

        if (checkInState !== CheckInState.NOT_CHECKED_IN) {
            return null;
        }

        return (
            <div className={styles.checkInButtonRow}>
                <CustomButton
                    theme={CustomButtonTheme.transparentThinBorder}
                    fullWidth={true}
                    onClick={onCheckInClick}
                >
                    {I18n.t('checkInNow')}
                </CustomButton>
            </div>
        );
    };
}
