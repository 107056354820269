//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update from 'immutability-helper';

import { NavigationTypes } from '@actions/navigation';
import { UserTypes }       from '@actions/user';

const initialState = Object.freeze({
    lastLogin:      null,
    boulderingHall: null,
    password:       '',
    rememberMe:     false,
    token:          null,
    username:       '',
});

const loginSucceeded = (action, state) => {
    return update(state, {
        lastLogin:      {
            $set: action.lastLogin.date,
        },
        password:       {
            $set: '',
        },
        token:          {
            $set: action.token,
        },
        username:       {
            $set: action.username,
        },
        boulderingHall: {
            $set: action.boulderingHall,
        },
    });
};

const logout = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const setPassword = (action, state) => {
    return update(state, {
        password: {
            $set: action.password,
        },
    });
};

const setUsername = (action, state) => {
    return update(state, {
        username: {
            $set: action.username,
        },
    });
};

const setRememberMe = (action, state) => {
    return update(state, {
        rememberMe: {
            $set: action.rememberMe,
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case UserTypes.LOGIN_SUCCEEDED:      return loginSucceeded(action, state);
        case NavigationTypes.RESET_STATE:
        case UserTypes.LOGOUT:               return logout(action, state);
        case UserTypes.SET_PASSWORD:         return setPassword(action, state);
        case UserTypes.SET_USERNAME:         return setUsername(action, state);
        case UserTypes.SET_REMEMBER_ME:      return setRememberMe(action, state);
        default:                             return state;
        // @formatter:on
    }
}
