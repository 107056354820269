//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@helper/Store';

const NavigationTypes = Object.freeze({
    REDIRECT:    'Navigation/REDIRECT',
    RESET_STATE: 'Navigation/RESET_STATE',
});

const redirect = makeActionCreator(
    NavigationTypes.REDIRECT,
    {
        route: null,
    },
);

const resetState = makeActionCreator(
    NavigationTypes.RESET_STATE,
    {},
);

const NavigationActions = Object.freeze({
    redirect,
    resetState,
});

export {
    NavigationActions,
    NavigationTypes,
};
