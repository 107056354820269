//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import Avatar          from '@/components/stateless/atomic/Avatar';
import BadgeLabel      from '@/components/stateless/atomic/BadgeLabel';
import BadgeLabelType  from '@/components/stateless/atomic/BadgeLabel/BadgeLabelType';
import CustomButton    from '@/components/stateless/atomic/CustomButton';
import Icon            from '@/components/stateless/atomic/Icon';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import MailToLink      from '@/components/stateless/composed/MailToLink';
import UserCheckInName from '@/components/stateless/composed/UserCheckInTable/UserCheckInRow/UserCheckInName';
import PropTypes       from '@components/PropTypes';
import ComponentHelper from '@helper/ComponentHelper';

import styles from '../styles.module.scss';

export default class UserCheckInRow extends React.Component {
    static propTypes = {
        address:         PropTypes.string,
        avatarSource:    PropTypes.string,
        email:           PropTypes.string,
        lastCheckInDate: PropTypes.string,
        lastCheckInTime: PropTypes.string,
        name:            PropTypes.string,
        onInfoClick:     PropTypes.func,
        subscription:    PropTypes.string,
    };

    static defaultProps = {
        address:         null,
        avatarSource:    null,
        email:           null,
        lastCheckInDate: null,
        lastCheckInTime: null,
        name:            null,
        onInfoClick:     _.noop,
        subscription:    null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    renderSubscription = () => {
        const subscription = this.props.subscription;

        if (!subscription) {
            return null;
        }

        return (
            <BadgeLabel
                type={BadgeLabelType.defaultLarge}
            >
                {subscription}
            </BadgeLabel>
        );
    };

    render() {
        const { avatarSource, name, lastCheckInDate, lastCheckInTime, email, address, onInfoClick } = this.props;

        return (
            <div className={styles.userCheckInRow}>
                <div>
                    <Avatar source={avatarSource} />
                </div>
                <div>
                    <UserCheckInName
                        name={name}
                        lastCheckInDate={lastCheckInDate}
                        lastCheckInTime={lastCheckInTime}
                    />
                </div>
                <div>
                    <MailToLink email={email} />
                </div>
                <div>
                    {address}
                </div>
                <div>
                    {this.renderSubscription()}
                </div>
                <div>
                    <CustomButton
                        classname={styles.infoButton}
                        onClick={onInfoClick}
                    >
                        <Icon
                            className={styles.infoIcon}
                            width={28}
                            height={28}
                            iconType={IconType.arrowRight}
                        />
                    </CustomButton>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}
