//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import CenteredContainerWithLogoAndTitle from '@/components/stateless/composed/CenteredContainerWithLogoAndTitle';

export default class NotFound extends React.Component {
    render() {
        return (
            <CenteredContainerWithLogoAndTitle title={I18n.t('pageNotFound')}>
                <p>{I18n.t('pageNotFoundMessage')}</p>
            </CenteredContainerWithLogoAndTitle>
        );
    }
}
