//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import Label           from '@/components/stateless/atomic/Label';
import PropTypes       from '@components/PropTypes';
import ComponentHelper from '@helper/ComponentHelper';
import Icon            from '@stateless/atomic/Icon';
import IconType        from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

export class CheckBox extends React.Component {
    static propTypes = {
        checked:        PropTypes.bool,
        noMarginBottom: PropTypes.bool,
        text:           PropTypes.string,
        valueChanged:   PropTypes.func,
    };

    static defaultProps = {
        checked:        false,
        noMarginBottom: false,
        text:           '',
        valueChanged:   _.noop,
    };

    static renderAffectingProps = [
        'checked',
        'noMarginBottom',
        'text',
    ];

    static renderAffectingStates = [];

    render() {
        return (
            <div
                className={
                    classNames(
                        styles.checkBoxWrapper,
                        {
                            [styles.checkBoxWrapperNoMarginBottom]: this.props.noMarginBottom,
                        },
                    )
                }
            >
                <div
                    className={styles.checkBoxWrapperClickArea}
                    onClick={this.props.valueChanged}
                >
                    {this.renderCheckBox()}
                    <Label
                        value={this.props.text}
                    />
                </div>
            </div>
        );
    }

    renderCheckBox = () => {
        return (
            <div
                className={
                    classNames(
                        styles.checkBox,
                        {
                            [styles.checkBoxChecked]: this.props.checked,
                        },
                    )
                }
            >
                {this.renderIcon()}
            </div>
        );
    };

    renderIcon = () => {
        if (this.props.checked) {
            return (
                <Icon iconType={IconType.cross} />
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default CheckBox;
