//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes         from '@/components/PropTypes';
import NoDataRow         from '@/components/stateless/composed/UserCheckInTable/NoDataRow';
import UserCheckInHeader from '@/components/stateless/composed/UserCheckInTable/UserCheckInHeader';
import UserCheckInRow    from '@/components/stateless/composed/UserCheckInTable/UserCheckInRow';
import ComponentHelper   from '@helper/ComponentHelper';

import styles from './styles.module.scss';

export default class UserCheckInTable extends React.Component {
    static propTypes = {
        checkIns:        PropTypes.array,
        onUserInfoClick: PropTypes.func,
    };

    static defaultProps = {
        checkIns:        [],
        onUserInfoClick: _.noop,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <div
                className={classNames(styles.userCheckInTable)}
            >
                <UserCheckInHeader className={styles.userCheckInRow} />
                {this.renderCheckIns()}
            </div>
        );
    }

    onUserInfoClick = (userId) => () => this.props.onUserInfoClick(userId);

    renderCheckIns = () => {
        const checkIns = _.get(this.props, 'checkIns', []);

        if (_.isEmpty(checkIns)) {
            return (
                <NoDataRow />
            );
        }

        return _.map(checkIns, this.renderCheckIn);
    };

    renderCheckIn = (checkIn) => {
        const { image, name, lastCheckInDate, lastCheckInTime, email, address, subscription, userId, userIri, iri } = checkIn;
        const key                                                                                                   = `${userIri}-${iri}`;

        return (
            <UserCheckInRow
                key={key}
                avatarSource={image}
                name={name}
                lastCheckInDate={lastCheckInDate}
                lastCheckInTime={lastCheckInTime}
                email={email}
                address={address}
                subscription={subscription}
                onInfoClick={this.onUserInfoClick(userId)}
            />
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}
