//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes                     from '@components/PropTypes';
import ComponentHelper               from '@helper/ComponentHelper';
import SelectionHelper               from '@helper/SelectionHelper';
import Icon                          from '@stateless/atomic/Icon';
import NavigationLinkOrClickCallback from '@stateless/composed/NavigationLinkOrClickCallback';

import IconButtonTheme from './IconButtonTheme';
import styles          from './styles.module.scss';

export class IconButton extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        iconType:  PropTypes.iconType,
        onClick:   PropTypes.func,
        theme:     PropTypes.oneOfObjectValues(IconButtonTheme),
        to:        PropTypes.string,
    };

    static defaultProps = {
        className: null,
        iconType:  null,
        onClick:   null,
        theme:     null,
        to:        null,
    };

    static renderAffectingProps = [
        'iconType',
        'theme',
        'to',
    ];

    static renderAffectingStates = [];

    getClassName = () => {
        const className = classNames(
            styles.iconButton,
            this.props.className,
            SelectionHelper.get(
                this.props.theme,
                {
                    [IconButtonTheme.bigIcon]:    styles.iconButtonBigIcon,
                    [IconButtonTheme.redRounded]: styles.iconButtonRedRounded,
                },
            ),
        );

        return className;
    };

    render() {
        const finalClassName = this.getClassName();
        const icon           = this.renderIcon();
        const props          = this.props;
        const to             = props.to;
        const onClick        = props.onClick;

        return (
            <NavigationLinkOrClickCallback
                className={finalClassName}
                onClick={onClick}
                to={to}
            >
                {icon}
            </NavigationLinkOrClickCallback>
        );
    }

    renderIcon = () => {
        return (
            <Icon iconType={this.props.iconType} />
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default IconButton;
