//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

import Languages from '@/constants/Languages';

class Language {
    /**
     * Creates a string using all passed values and used the word "and" to concat the last value.
     * So ['foo', '123', 'bar'] will become 'foo, 123 and bar'.
     *
     * @param values
     * @returns {string|*}
     */
    static andString(values) {
        return this.listString(
            values,
            ', ',
            I18n.t('and'),
        );
    }

    static getDefaultLanguage() {
        return Languages.german;
    }

    static listString(values, separator, endSeparator) {
        if (values.length > 1) {
            const stringData = [...values];
            const lastItem   = stringData.pop();
            const listString = [
                stringData.join(separator),
                endSeparator,
                lastItem,
            ].join(' ');

            return listString;
        }

        return values[0];
    }

    /**
     * Creates a string using all passed values and used the word "or" to concat the last value.
     * So ['foo', '123', 'bar'] will become 'foo, 123 or bar'.
     *
     * @param values
     * @returns {string|*}
     */
    static orString(values) {
        return this.listString(
            values,
            ', ',
            I18n.t('or'),
        );
    }
}

export default Language;
