//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                      from 'lodash';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import AlertBoxManager        from '@/components/connected/AlertBoxManager';
import DashboardTitle         from '@/components/connected/DashboardTitle';
import Header                 from '@/components/connected/Header';
import UserCheckInInfoOverlay from '@/components/connected/UserCheckInInfoOverlay';
import UserCheckInTable       from '@/components/connected/UserCheckInTable';
import ComponentHelper        from '@/helper/ComponentHelper';
import { AlertBoxActions }    from '@actions/alertBox';
import PropTypes              from '@components/PropTypes';

import styles from './styles.module.scss';

const mapStateToProps = (state, props) => {
    return {
        userId: _.get(props, 'match.params.userId'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ...AlertBoxActions,
    }, dispatch);
};

class Dashboard extends React.Component {
    static propTypes = {
        userId: PropTypes.string,
    };

    static defaultProps = {
        userId: null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    renderUserInfo = () => {
        const { userId } = this.props;

        if (userId) {
            return (
                <UserCheckInInfoOverlay />
            );
        }

        return null;
    };

    renderTitle = () => {
        return (
            <div className={styles.titleContainer}>
                <DashboardTitle />
            </div>
        );
    };

    renderUserCheckInTable = () => {
        return (
            <div>
                <UserCheckInTable />
            </div>
        );
    };

    render() {
        return (
            <>
                <AlertBoxManager />
                {this.renderUserInfo()}
                <div className={styles.dashboardContainer}>
                    <Header />
                    {this.renderTitle()}
                    {this.renderUserCheckInTable()}
                </div>
            </>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(this, nextProps, nextState);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
