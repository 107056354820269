//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import UserInfoRow     from '@/components/stateless/atomic/UserInfo/UserInfoRow';
import PropTypes       from '@components/PropTypes';
import ComponentHelper from '@helper/ComponentHelper';

import styles from './styles.module.scss';

export default class UserInfo extends React.Component {
    static propTypes = {
        address:        PropTypes.string,
        customerNumber: PropTypes.string,
        email:          PropTypes.string,
        lastCheckIn:    PropTypes.date,
        subscription:   PropTypes.string,
        time:           PropTypes.string,
    };

    static defaultProps = {
        address:        null,
        customerNumber: null,
        email:          null,
        lastCheckIn:    null,
        subscription:   null,
        time:           null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <div
                className={classNames(styles.userInfo)}
            >
                {this.renderRows()}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }

    renderRow = (keyName, value) => {
        return (
            <UserInfoRow
                keyName={keyName}
                value={value}
            />
        );
    };

    renderRows = () => {
        const { lastCheckIn, time, address, email, subscription, customerNumber } = this.props;

        return [
            this.renderRow('lastCheckIn', lastCheckIn),
            this.renderRow('time', time),
            this.renderRow('address', address),
            this.renderRow('email', email),
            this.renderRow('subscription', subscription),
            this.renderRow('customerNumber', customerNumber),
        ];
    };
}
