//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';

import ClearCache      from 'react-clear-cache';

import ComponentHelper from '@helper/ComponentHelper';

export class CacheInvalidator extends React.Component {
    static propTypes = {};

    static defaultProps = {};

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <ClearCache
                auto={true}
                duration={1000 * 60 * 30}
            >
                {() => {
                    // This cannot be empty otherwise react-clear-cache will crash
                    return '';
                }}
            </ClearCache>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default CacheInvalidator;
