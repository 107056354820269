//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import UserMapper from '@/helper/UserMapper';

const mapCheckIn = (checkIn) => {
    const { user } = checkIn;

    return UserMapper.mapUserDataWithLatestCheckin(user, checkIn);
};

const mapCheckIns = (checkIns) => {
    return _.map(checkIns, mapCheckIn);
};

export default {
    mapCheckIn,
    mapCheckIns,
};
