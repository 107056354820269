//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                      from 'lodash';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes             from '@/components/PropTypes';
import LoadingIndicator      from '@/components/connected/LoadingIndicator';
import StatelessHeader       from '@/components/stateless/composed/Header';
import Routes                from '@/constants/Routes';
import { DashboardActions }  from '@/store/actions/dashboard';
import { NavigationActions } from '@/store/actions/navigation';
import { UserActions }       from '@/store/actions/user';
import ComponentHelper       from '@helper/ComponentHelper';

const mapStateToProps = (state, props) => (
    {
        searchQuery: _.get(state, 'dashboard.searchQuery'),
    }
);

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        ...DashboardActions,
        ...NavigationActions,
        ...UserActions,
    }, dispatch)
);

class Header extends React.Component {
    static propTypes = {
        executeSearch:  PropTypes.func,
        logout:         PropTypes.func,
        redirect:       PropTypes.func,
        searchQuery:    PropTypes.string,
        setSearchQuery: PropTypes.func,
    };

    static defaultProps = {
        executeSearch:  _.noop,
        logout:         _.noop,
        redirect:       _.noop,
        searchQuery:    null,
        setSearchQuery: _.noop,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    onInputChange = (event) => {
        const value = _.get(event, 'target.value');

        this.props.setSearchQuery({
            searchQuery: value,
        });
    };

    onLogoutButtonClick = () => {
        this.props.logout();
    };

    onSearchButtonClick = (value) => {
        this.props.executeSearch({
            searchQuery: value,
        });
    };

    onLogoClick = () => {
        this.props.redirect({
            route: Routes.home,
        });
    };

    render() {
        return (
            <StatelessHeader
                loadingIndicator={<LoadingIndicator />}
                onLogoutButtonClick={this.onLogoutButtonClick}
                onSearchButtonClick={this.onSearchButtonClick}
                onInputChange={this.onInputChange}
                onLogoClick={this.onLogoClick}
                value={this.props.searchQuery}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(this, nextProps, nextState);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
