//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes       from '@components/PropTypes';
import ComponentHelper from '@helper/ComponentHelper';

import BadgeLabelType from './BadgeLabelType';
import styles         from './styles.module.scss';

export class BadgeLabel extends React.Component {
    static propTypes = {
        children: PropTypes.children,
        type:     PropTypes.oneOfObjectValues(BadgeLabelType),
    };

    static defaultProps = {
        children: null,
        type:     null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = Object.keys(this.defaultProps);

    render() {
        const type = this.props.type;

        return (
            <span
                className={classNames(
                    styles.badgeLabel,
                    {
                        [styles.defaultMedium]: type === BadgeLabelType.defaultMedium,
                        [styles.defaultLarge]:  type === BadgeLabelType.defaultLarge,
                        [styles.extraDeals]:    type === BadgeLabelType.extraDeals,
                    },
                )}
            >
                {this.props.children}
            </span>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default BadgeLabel;
