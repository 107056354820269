//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import String from '@/helper/String';

const getAddressArrayFromObject = (address) => {
    let addressArray   = [];
    const street       = _.get(address, 'street');
    const streetNumber = _.get(address, 'houseNumber');
    const postalCode   = _.get(address, 'postalCode');
    const city         = _.get(address, 'city');
    const country      = _.get(address, 'country');

    if (street) {
        addressArray.push(`${street} ${streetNumber}`);
    }

    if (postalCode || city) {
        addressArray.push(`${postalCode} ${city}`);
    }

    if (country) {
        addressArray.push(country);
    }

    addressArray = String.trimAllStrings(addressArray);

    return addressArray;
};

const getAddressStringFromObject = (address) => {
    const addressArray = getAddressArrayFromObject(address);

    return addressArray.join('\n');
};

export default {
    getAddressArrayFromObject,
    getAddressStringFromObject,
};
