//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { CALL_HISTORY_METHOD } from 'connected-react-router';
import { LOCATION_CHANGE }     from 'connected-react-router';
import I18n                    from 'i18next';
import _                       from 'lodash';
import { REHYDRATE }           from 'redux-persist';
import { PERSIST }             from 'redux-persist';
import { FLUSH }               from 'redux-persist';
import { PAUSE }               from 'redux-persist';
import { PURGE }               from 'redux-persist';
import { REGISTER }            from 'redux-persist';

import { CheckInTypes }    from '@/store/actions/checkIn';
import { AlertBoxActions } from '@actions/alertBox';
import { AlertBoxTypes }   from '@actions/alertBox';
import { NavigationTypes } from '@actions/navigation';
import { UserTypes }       from '@actions/user';
import Environment         from '@helper/Environment';
import Token               from '@helper/Token';

const whitelistedReactActions     = [
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    CALL_HISTORY_METHOD,
    LOCATION_CHANGE,
];
const actionBlockerConfigurations = {
    jwtTokenNotValid: {
        actionsToBlock:               [],
        blockAllActionsExceptAllowed: true,
        allowedActions:               [
            AlertBoxTypes.CLEAR,
            AlertBoxTypes.SHOW_ERROR,
            AlertBoxTypes.SHOW_ERROR_TRANSLATED,
            AlertBoxTypes.SHOW_SUCCESS,
            AlertBoxTypes.SHOW_SUCCESS_TRANSLATED,
            NavigationTypes.REDIRECT,
            CheckInTypes.FETCH_CHECK_INS_SUCCEEDED,
            CheckInTypes.FETCH_CHECK_INS_FAILED,
            UserTypes.LOGIN,
            UserTypes.LOGIN_FAILED,
            UserTypes.LOGIN_SUCCEEDED,
            UserTypes.LOGOUT,
            UserTypes.SET_PASSWORD,
            UserTypes.SET_USERNAME,
            UserTypes.SET_REMEMBER_ME,
            UserTypes.TRY_RESTORE_TOKEN,
        ],
        shouldActionsBlockedFunction: (action, state) => {
            const token = _.get(state, ['user', 'token']);

            return (
                !token ||
                !Token.isValidJWTToken(token)
            );
        },
    },
};

function actionBlockerMiddleware() {
    return ({ dispatch, getState }) => (next) => (action) => {
        let skipAction = false;

        for (const configurationKey in actionBlockerConfigurations) {
            const configuration                                                = actionBlockerConfigurations[configurationKey];
            const { allowedActions, actionsToBlock, dataPropertyToBlockValue } = configuration;
            const { dataPropertyInReducer, dataPropertyToBlock }               = configuration;
            const { shouldActionsBlockedFunction, blockedErrorMessage }        = configuration;
            const blockAllActionsExceptAllowed                                 = _.get(configuration, 'blockAllActionsExceptAllowed', false);
            const actionType                                                   = action.type;
            const actionTypeContainedInBlocked                                 = _.includes(actionsToBlock, actionType);
            const actionTypeContainedInAllowed                                 = _.includes(allowedActions, actionType);

            if (
                (
                    actionTypeContainedInBlocked ||
                    blockAllActionsExceptAllowed
                ) &&
                !actionTypeContainedInAllowed &&
                !_.includes(whitelistedReactActions, actionType)
            ) {
                if (shouldActionsBlockedFunction) {
                    const shouldBlockAction = shouldActionsBlockedFunction(action, getState());

                    if (shouldBlockAction) {
                        console.info(`actionBlockerMiddleware: blocked action by ${configurationKey}:`, action);

                        if (blockedErrorMessage) {
                            dispatch(AlertBoxActions.showErrorAlert({
                                text: I18n.t(blockedErrorMessage),
                            }));
                        }

                        skipAction = true;
                    }

                    break;
                }

                const stateDataPropertyValue = _.get(getState(), [dataPropertyInReducer, dataPropertyToBlock]);

                if (_.eq(stateDataPropertyValue, dataPropertyToBlockValue)) {
                    console.info(`actionBlockerMiddleware: blocked action by ${configurationKey}:`, action);

                    skipAction = true;
                }
            }
        }

        if (
            !skipAction ||
            Environment.isTest()
        ) {
            return next(action);
        }

        return next;
    };
}

export default actionBlockerMiddleware;
