//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push } from 'connected-react-router';
import { put }  from 'redux-saga/effects';

import Routes                from '@/constants/Routes';
import { NavigationActions } from '@actions/navigation';
import SagaStateHelper       from '@helper/SagaStateHelper';

function* locationChange() {
    const route = SagaStateHelper.selectFromState(
        'router',
        'location',
        'pathname',
    );

    if (route === Routes.login) {
        yield put(NavigationActions.resetState());
    }
}

function* redirect(action) {
    yield put(push(action.route));
}

export default {
    locationChange,
    redirect,
};
