//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes       from '@/components/PropTypes';
import TitleTheme      from '@/components/stateless/atomic/Title/TitleTheme';
import ComponentHelper from '@helper/ComponentHelper';

import styles from './styles.module.scss';

export class Title extends React.Component {
    static propTypes = {
        text:       PropTypes.string,
        theme:      PropTypes.oneOfObjectValues(TitleTheme),
        underlined: PropTypes.bool,
    };

    static defaultProps = {
        text:       null,
        theme:      TitleTheme.default,
        underlined: true,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        const { text, theme, underlined } = this.props;

        return (
            <div
                className={classNames(styles.title, {
                    [styles.smallTitle]: theme === TitleTheme.small,
                    [styles.whiteTitle]: theme === TitleTheme.white,
                })}
            >
                {text}
                {underlined && (
                    <div className={styles.underline} />
                )}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(this, nextProps, nextState);
    }
}

export default Title;
