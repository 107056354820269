//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import Title           from '@/components/stateless/atomic/Title';
import TitleTheme from '@/components/stateless/atomic/Title/TitleTheme';
import ComponentHelper from '@helper/ComponentHelper';

import styles     from './styles.module.scss';

export default class NoDataRow extends React.Component {
    static propTypes = {};

    static defaultProps = {};

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <div className={styles.noDataRow}>
                <Title
                    text={I18n.t('noData')}
                    theme={TitleTheme.small}
                    underlined={false}
                />
                <span>
                    {I18n.t('noDataExplanation')}
                </span>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(this, nextProps, nextState);
    }
}
