//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';

import _                      from 'lodash';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { AlertBoxActions }    from '@actions/alertBox';
import PropTypes              from '@components/PropTypes';
import ComponentHelper        from '@helper/ComponentHelper';
import AlertBoxType           from '@stateless/atomic/AlertBox/AlertBoxType';
import AlertBoxWrapper        from '@stateless/composed/AlertBoxWrapper';

const mapStateToProps = (state) => {
    return {
        alertBoxes: _.get(state, 'alertBox.alertBoxes', []),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(AlertBoxActions, dispatch);
};

class AlertBoxManager extends React.Component {
    static propTypes = {
        alertBoxes:       PropTypes.array,
        clearSingleAlert: PropTypes.func,
        filterByType:     PropTypes.oneOfObjectValues(AlertBoxType),
    };

    static defaultProps = {
        alertBoxes:       [],
        clearSingleAlert: _.noop,
        filterByType:     null,
    };

    static renderAffectingProps = [
        'alertBoxes',
    ];

    static renderAffectingStates = [];

    getAlertBoxes = () => {
        const filterByType = this.props.filterByType;
        const alertBoxes   = this.props.alertBoxes;

        if (filterByType) {
            return _.filter(
                alertBoxes,
                {
                    type: filterByType,
                },
            );
        }

        return alertBoxes;
    };

    hideButtonPressed = (index) => {
        this.props.clearSingleAlert({
            index,
        });
    };

    render() {
        return (
            <AlertBoxWrapper
                alertBoxes={this.getAlertBoxes()}
                hideButtonPressed={this.hideButtonPressed}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AlertBoxManager);
