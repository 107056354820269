//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _           from 'lodash';
import { connect } from 'react-redux';

import PropTypes                 from '@/components/PropTypes';
import LoadingIndicatorStateless from '@/components/stateless/atomic/LoadingIndicator';
import ComponentHelper           from '@/helper/ComponentHelper';

const mapStateToProps = (state) => (
    {
        isLoading: _.get(state, 'loading.isLoading', false),
    }
);

class LoadingIndicator extends React.Component {
    static propTypes = {
        isLoading: PropTypes.bool,
    };

    static defaultProps = {
        isLoading: false,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <LoadingIndicatorStateless
                isLoading={this.props.isLoading}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(this, nextProps, nextState);
    }
}

export default connect(mapStateToProps, {})(LoadingIndicator);
