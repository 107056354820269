//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import React           from 'react';

import PropTypes       from '@components/PropTypes';
import ComponentHelper from '@helper/ComponentHelper';
import Link            from '@stateless/atomic/Link';

export default class NavigationLinkOrClickCallback extends React.Component {
    static propTypes = {
        children:       PropTypes.children,
        className:      PropTypes.string,
        onClick:        PropTypes.func,
        onClickElement: PropTypes.string,
        to:             PropTypes.string,
    };

    static defaultProps = {
        children:       null,
        className:      null,
        onClick:        null,
        onClickElement: 'div',
        to:             null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        const { children, className, onClick } = this.props;
        const to                               = this.props.to;

        if (to) {
            return (
                <Link
                    className={className}
                    to={to}
                >
                    {children}
                </Link>
            );
        }

        if (onClick) {
            const onClickElement = React.createElement(
                this.props.onClickElement,
                {
                    className,
                    onClick,
                },
                children,
            );

            return onClickElement;
        }

        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}
// TODO-Skeleton end
