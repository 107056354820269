//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import jwtDecode from 'jwt-decode';
import _         from 'lodash';

export default class Token {
    static isValidJWTToken = (jwtToken) => {
        return Token.getTimeTillExpiration(jwtToken) > 0;
    };

    static getTimeTillExpiration = (jwtToken) => {
        if (jwtToken) {
            try {
                const decodedToken  = jwtDecode(jwtToken);
                const expiration    = _.get(decodedToken, 'exp', 0) * 1000;
                const currentMillis = Date.now();

                return expiration - currentMillis;
            } catch (error) {
                console.error(`Error while calculating jwt token expiration: "${jwtToken}"`, error);
            }
        }

        return 0;
    };
}
