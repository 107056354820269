//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import IconArrowRight  from '@assets/icons/arrowRight.svg';
import IconCross       from '@assets/icons/cross.svg';
import IconSearch      from '@assets/icons/search.svg';
import PropTypes       from '@components/PropTypes';
import ComponentHelper from '@helper/ComponentHelper';
import SelectionHelper from '@helper/SelectionHelper';

import IconType from './IconType';
import styles   from './styles.module.scss';

export class Icon extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        height:    PropTypes.number,
        iconType:  PropTypes.iconType,
        onClick:   PropTypes.func,
        width:     PropTypes.number,
    };

    static defaultProps = {
        className: null,
        height:    null,
        iconType:  null,
        onClick:   _.noop,
        width:     null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    getStyle = () => {
        let { width, height } = this.props;

        if (width && !height) {
            height = width;
        } else if (height && !width) {
            width = height;
        }

        if (width && height) {
            return {
                width:  `${width}px`,
                height: `${height}px`,
            };
        }

        return {};
    };

    render() {
        const { className, onClick } = this.props;

        return (
            <span
                onClick={onClick}
                className={classNames(styles.icon, className)}
                style={this.getStyle()}
            >
                {this.renderSvg()}
            </span>
        );
    }

    renderSvg = () => {
        return SelectionHelper.get(
            this.props.iconType,
            {
                [IconType.arrowRight]: <IconArrowRight />,
                [IconType.cross]:      <IconCross />,
                [IconType.search]:     <IconSearch />,
            },
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default Icon;
