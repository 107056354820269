//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _        from 'lodash';
import { call } from 'redux-saga/effects';
import { put }  from 'redux-saga/effects';

import * as Api              from '@/api';
import Routes                from '@/constants/Routes';
import CheckInMapper         from '@/helper/CheckInMapper';
import Route                 from '@/helper/Route';
import SagaStateHelper       from '@/helper/SagaStateHelper';
import UserMapper            from '@/helper/UserMapper';
import { DashboardActions }  from '@/store/actions/dashboard';
import { NavigationActions } from '@/store/actions/navigation';
import { CheckInActions }    from '@actions/checkIn';
import HydraHelper           from '@helper/Hydra';

function* getSearchState() {
    return yield SagaStateHelper.selectFromState('dashboard', 'searchQuery');
}

function* fetchAllCheckIns() {
    const response = yield call(Api.context.checkIn.fetch);

    if (response.ok) {
        let checkIns = _.map(
            HydraHelper.getMembersFromResponse(response.data),
            (checkIn) => (
                {
                    ...checkIn,
                    user: HydraHelper.removeHydraFromObject(_.get(checkIn, 'user')),
                }
            ),
        );
        checkIns     = CheckInMapper.mapCheckIns(checkIns);

        yield put(CheckInActions.fetchCheckInsSucceeded({
            checkIns,
        }));
    } else {
        yield put(CheckInActions.fetchCheckInsFailed());
    }
}

function* fetchSearch(search) {
    const response = yield call(Api.context.user.search, search);

    if (response.ok) {
        let checkIns = _.map(
            HydraHelper.getMembersFromResponse(response.data),
            (user) => (
                {
                    ...(
                        _.get(user, 'lastCheckIn')
                    ),
                    user,
                }
            ),
        );
        checkIns     = CheckInMapper.mapCheckIns(checkIns);

        if (_.size(checkIns) === 1) {
            const stateCheckIns = yield SagaStateHelper.selectFromState('checkIn', 'checkIns');
            const userIdPath    = '[0].user.id';
            const userId        = _.get(checkIns, userIdPath);
            const stateUserId   = _.get(stateCheckIns, userIdPath);

            if (
                userId &&
                !_.isEqual(userId, stateUserId)
            ) {
                const route = Route.buildPath(Routes.userInfo, {
                    userId,
                });

                yield put(NavigationActions.redirect({
                    route,
                }));
            }
        }

        yield put(CheckInActions.fetchCheckInsSucceeded({
            checkIns,
        }));
    } else {
        yield put(CheckInActions.fetchCheckInsFailed());
    }
}

function* fetchCheckIns() {
    const search = yield getSearchState();

    if (search) {
        yield call(fetchSearch, search);
    } else {
        yield call(fetchAllCheckIns);
    }
}

function* getSearchFromUrl() {
    const search      = _.get(window, 'location.search');
    const query       = new URLSearchParams(search);
    const searchParam = query.get('query');

    return searchParam;
}

function* updateCheckIns() {
    const searchQueryState = yield getSearchState();
    const searchQueryUrl   = yield getSearchFromUrl();
    const pathname         = yield SagaStateHelper.selectFromState(
        'router',
        'location',
        'pathname',
    );

    if (
        (
            pathname === Routes.home ||
            !_.isNil(searchQueryUrl)
        ) &&
        searchQueryUrl !== searchQueryState
    ) {
        yield put(DashboardActions.setSearchQuery({
            searchQuery: searchQueryUrl,
        }));
    }

    yield put(CheckInActions.fetchCheckIns());
}

function* checkInUser() {
    const userId         = yield SagaStateHelper.selectFromState('dashboard', 'activeCheckInInfoUser', 'userIri');
    const boulderingHall = yield SagaStateHelper.selectFromState('user', 'boulderingHall');
    const response       = yield call(Api.context.checkIn.checkIn, userId, boulderingHall);

    if (response.ok) {
        const checkIn = HydraHelper.removeHydraFromObject(response.data);

        yield put(CheckInActions.checkInUserSucceeded({
            checkIn,
        }));
    } else {
        yield put(CheckInActions.checkInUserFailed());
    }
}

function* checkInUserSucceeded(action) {
    const checkIn = _.get(action, 'checkIn');
    const user    = _.get(checkIn, 'user');

    yield put(DashboardActions.fetchUserSucceeded({
        user: UserMapper.mapUserDataWithLatestCheckin(user, checkIn),
    }));
}

function* checkInUserFailed() {
    yield put(DashboardActions.setCheckInFailed());
}

export default {
    fetchCheckIns,
    updateCheckIns,
    checkInUser,
    checkInUserSucceeded,
    checkInUserFailed,
};
