//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n      from 'i18next';
import { put }   from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import { call }  from 'redux-saga/effects';

import * as Api              from '@/api';
import Routes                from '@/constants/Routes';
import { AlertBoxActions }   from '@actions/alertBox';
import { NavigationActions } from '@actions/navigation';
import { UserActions }       from '@actions/user';
import SagaStateHelper       from '@helper/SagaStateHelper';
import Token                 from '@helper/Token';

const afterLoginRoute = Routes.home;

/**
 * All routes that are defined in this array are reachable without the
 * requirement of a active/valid session. If no session is present on
 * a route that is not in this array, the user is automatically redirected
 * to Routes.login (in the restoreToken-saga).
 *
 * @type {(string)[]}
 */
const routesWithoutSession = [
    Routes.imprint,
    Routes.login,
    Routes.screenDesign,
];

function* login() {
    yield put(AlertBoxActions.clearAlerts());

    const user     = yield SagaStateHelper.selectFromState('user');
    const response = yield call(
        Api.context.user.login,
        user.username,
        user.password,
    );

    if (response.ok) {
        const { lastLogin, token, username, boulderingHall } = response.data;

        Api.context.user.setToken(token);

        yield put(UserActions.loginSucceeded({
            lastLogin,
            token,
            username,
            boulderingHall,
        }));
    } else {
        yield put(UserActions.loginFailed());
    }
}

function* loginFailed() {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('loginError'),
    }));
}

function* loginSucceeded() {
    const boulderingHall = yield SagaStateHelper.selectFromState(
        'user',
        'boulderingHall',
    );

    if (!boulderingHall) {
        yield put(UserActions.logout());

        return;
    }

    yield put(NavigationActions.redirect({
        route: afterLoginRoute,
    }));
}

function* logout() {
    Api.context.user.setToken(null);

    yield put(NavigationActions.redirect({
        route: Routes.login,
    }));
}

function* restoreToken() {
    const pathname        = yield SagaStateHelper.selectFromState(
        'router',
        'location',
        'pathname',
    );
    const browserPathname = window.location.pathname;
    const user            = yield SagaStateHelper.selectFromState('user');

    if (user.token) {
        if (Token.isValidJWTToken(user.token)) {
            Api.context.user.setToken(user.token);

            if (
                pathname === Routes.login ||
                browserPathname === Routes.login
            ) {
                yield put(NavigationActions.redirect({
                    route: afterLoginRoute,
                }));
            }
        } else {
            yield put(UserActions.logout());
        }
    } else if (
        routesWithoutSession.indexOf(pathname) === -1 ||
        routesWithoutSession.indexOf(browserPathname) === -1
    ) {
        // This delay is important otherwise the redirect will not work properly
        yield delay(50);
        yield put(NavigationActions.redirect({
            route: Routes.login,
        }));
    }
}

export default {
    login,
    loginFailed,
    loginSucceeded,
    logout,
    restoreToken,
};
