//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { LOCATION_CHANGE } from 'connected-react-router';
import { REHYDRATE }       from 'redux-persist';
import { all }             from 'redux-saga/effects';
import { takeLatest }      from 'redux-saga/effects';
import { takeEvery }       from 'redux-saga/effects';

// Actions
import { AlertBoxTypes }   from '@actions/alertBox';
import { CheckInTypes }    from '@actions/checkIn';
import { DashboardTypes }  from '@actions/dashboard';
import { LoadingTypes }    from '@actions/loading';
import { NavigationTypes } from '@actions/navigation';
import { UserTypes }       from '@actions/user';

// Sagas
import AlertBoxSagas   from './alertBox';
import CheckInSagas    from './checkIn';
import DashboardSagas  from './dashboard';
import LoadingSagas    from './loading';
import NavigationSagas from './navigation';
import PreLoadSagas    from './preload';
import UserSagas       from './user';

function* root() {
    yield all([
        // @formatter:off
        takeEvery([CheckInTypes.FETCH_CHECK_INS],                CheckInSagas.fetchCheckIns),
        takeLatest([CheckInTypes.CHECK_IN_USER],                 CheckInSagas.checkInUser),
        takeLatest([CheckInTypes.CHECK_IN_USER_FAILED],          CheckInSagas.checkInUserFailed),
        takeLatest([CheckInTypes.CHECK_IN_USER_SUCCEEDED],       CheckInSagas.checkInUserSucceeded),

        takeLatest([UserTypes.LOGIN],                            UserSagas.login),
        takeLatest([UserTypes.LOGIN_FAILED],                     UserSagas.loginFailed),
        takeEvery([UserTypes.LOGIN_SUCCEEDED],                   UserSagas.loginSucceeded),
        takeLatest([UserTypes.LOGOUT],                           UserSagas.logout),
        takeLatest([UserTypes.TRY_RESTORE_TOKEN],                UserSagas.restoreToken),

        takeLatest([DashboardTypes.EXECUTE_SEARCH],              DashboardSagas.executeSearch),
        takeLatest([DashboardTypes.FETCH_USER],                  DashboardSagas.fetchUser),
        takeLatest([DashboardTypes.FETCH_USER_FAILED],           DashboardSagas.fetchUserFailed),

        takeLatest([AlertBoxTypes.SHOW_ERROR_TRANSLATED],        AlertBoxSagas.showErrorAlertTranslated),
        takeLatest([AlertBoxTypes.SHOW_SUCCESS_TRANSLATED],      AlertBoxSagas.showSuccessAlertTranslated),

        takeLatest([LoadingTypes.OVERLAY_CLICKED],               LoadingSagas.overlayClicked),

        takeLatest([NavigationTypes.REDIRECT],                   NavigationSagas.redirect),

        takeLatest([LOCATION_CHANGE],                            NavigationSagas.locationChange),
        takeLatest([LOCATION_CHANGE],                            CheckInSagas.updateCheckIns),

        takeLatest([REHYDRATE],                                  PreLoadSagas.preLoadData),
        takeLatest([REHYDRATE],                                  PreLoadSagas.recurringData),
        // @formatter:on
    ]);
}

export default {
    root,
};
