//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { connectRouter }      from 'connected-react-router';
import { combineReducers }    from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

import alertBoxReducer  from './alertBox';
import checkInReducer   from './checkIn';
import dashboardReducer from './dashboard';
import loadingReducer   from './loading';
import userReducer      from './user';

export default (history) => withReduxStateSync(combineReducers({
    alertBox:  alertBoxReducer,
    checkIn:   checkInReducer,
    dashboard: dashboardReducer,
    loading:   loadingReducer,
    user:      userReducer,
    // This key must be "router"
    // @see https://github.com/supasate/connected-react-router
    router: connectRouter(history),
}));
