//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { LOCATION_CHANGE } from 'connected-react-router';
import update              from 'immutability-helper';

import { UserTypes }      from '@/store/actions/user';
import { DashboardTypes } from '@actions/dashboard';

const initialState = Object.freeze({
    activeCheckInInfoUser: null,
    searchQuery:           null,
});

const fetchUserSucceeded = (action, state) => {
    return update(state, {
        activeCheckInInfoUser: {
            $set: action.user,
        },
    });
};

const setSearchQuery = (action, state) => {
    return update(state, {
        searchQuery: {
            $set: action.searchQuery,
        },
    });
};

const resetUser = (action, state) => {
    return update(state, {
        activeCheckInInfoUser: {
            $set: null,
        },
    });
};

const setCheckInFailed = (action, state) => {
    return update(state, {
        activeCheckInInfoUser: {
            checkInFailed: {
                $set: true,
            },
        },
    });
};

const logout = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case DashboardTypes.FETCH_USER_SUCCEEDED: return fetchUserSucceeded(action, state);
        case DashboardTypes.SET_SEARCH_QUERY:     return setSearchQuery(action, state);
        case DashboardTypes.SET_CHECK_IN_FAILED:  return setCheckInFailed(action, state);
        case LOCATION_CHANGE:                     return resetUser(action, state);
        case UserTypes.LOGOUT:                    return logout(action, state);
        default:                                  return state;
        // @formatter:on
    }
}
