//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import Link            from '@/components/stateless/atomic/Link';
import MailHelper      from '@/helper/MailHelper';
import PropTypes       from '@components/PropTypes';
import ComponentHelper from '@helper/ComponentHelper';

export default class MailToLink extends React.Component {
    static propTypes = {
        blindCarbonAddress: PropTypes.string,
        body:               PropTypes.string,
        carbonAddress:      PropTypes.string,
        children:           PropTypes.children,
        className:          PropTypes.string,
        email:              PropTypes.string,
        subject:            PropTypes.string,
    };

    static defaultProps = {
        blindCarbonAddress: null,
        body:               null,
        carbonAddress:      null,
        children:           null,
        className:          '',
        email:              null,
        subject:            null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    handleClick = (event) => {
        const { blindCarbonAddress, body, carbonAddress, email, subject } = this.props;

        MailHelper.openMailWindow(email, subject, body, carbonAddress, blindCarbonAddress);
        event.preventDefault();
        event.stopPropagation();
    };

    render() {
        const { children, className, email } = this.props;

        return (
            <Link
                to={`mailto:${email}`}
                target={'_blank'}
                onClick={this.handleClick}
                className={className}
            >
                {children || email}
            </Link>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}
