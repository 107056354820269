//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createMatchSelector } from 'connected-react-router';
import I18n                    from 'i18next';
import _                       from 'lodash';
import { put }                 from 'redux-saga/effects';
import { select }              from 'redux-saga/effects';
import { call }                from 'redux-saga/effects';

import * as Api              from '@/api';
import Routes                from '@/constants/Routes';
import Hydra                 from '@/helper/Hydra';
import SagaStateHelper       from '@/helper/SagaStateHelper';
import UserMapper            from '@/helper/UserMapper';
import { AlertBoxActions }   from '@actions/alertBox';
import { DashboardActions }  from '@actions/dashboard';
import { NavigationActions } from '@actions/navigation';

function* getUserId() {
    const matchSelector = yield select((state) => createMatchSelector({
        path: Routes.userInfo,
    })(state));
    const userId        = _.get(matchSelector, 'params.userId');

    return userId;
}

function* fetchUser(action) {
    const userId   = _.defaultTo(_.get(action, 'userId'), yield call(getUserId));
    const response = yield call(Api.context.user.fetchUser, userId);

    if (response.ok) {
        const user = UserMapper.mapUserDataWithLatestCheckin(Hydra.removeHydraFromObject(response.data));

        yield put(DashboardActions.fetchUserSucceeded({
            user,
        }));
    } else {
        yield put(DashboardActions.fetchUserFailed());
    }
}

function* fetchUserFailed() {
    yield put(NavigationActions.redirect({
        route: Routes.home,
    }));
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('fetchUserError'),
    }));
}

function* executeSearch(action) {
    const searchQueryAction = _.get(action, 'searchQuery');
    const searchQueryState  = yield SagaStateHelper.selectFromState('dashboard', 'searchQuery');

    if (
        searchQueryAction &&
        searchQueryState !== searchQueryAction
    ) {
        yield put(DashboardActions.setSearchQuery({
            searchQuery: searchQueryAction,
        }));
    }

    const searchQuery = (
        searchQueryAction ||
        searchQueryState
    );

    if (searchQuery) {
        const routeWithSearchParams = `${Routes.search}?query=${searchQuery}`;

        yield put(NavigationActions.redirect({
            route: routeWithSearchParams,
        }));
    }
}

export default {
    executeSearch,
    fetchUser,
    fetchUserFailed,
};
