//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }   from 'redux-saga/effects';
import { call }  from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';

import { CheckInActions } from '@/store/actions/checkIn';
import { UserActions }    from '@actions/user';

function* preLoadData() {
    yield put(UserActions.tryRestoreToken());
}

function* loadRecurringData() {
    yield put(CheckInActions.fetchCheckIns({
        loadingLevel: undefined,
    }));
}

function* recurringData() {
    yield delay(30 * 1000);
    yield call(loadRecurringData);
    yield call(recurringData);
}

export default {
    preLoadData,
    recurringData,
};
