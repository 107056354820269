//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import PropTypes       from '@/components/PropTypes';
import ComponentHelper from '@/helper/ComponentHelper';

import styles from './styles.module.scss';

export default class UserCheckInName extends React.Component {
    static propTypes = {
        lastCheckInDate: PropTypes.string,
        lastCheckInTime: PropTypes.string,
        name:            PropTypes.string,
    };

    static defaultProps = {
        lastCheckInDate: null,
        lastCheckInTime: null,
        name:            null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        const { name, lastCheckInDate, lastCheckInTime } = this.props;

        return (
            <div className={styles.userCheckInDateAndName}>
                <span className={styles.userCheckInName}>
                    {name}
                </span>
                <div>
                    {I18n.t('checkIn')}
                    <div className={styles.checkInDateAndTime}>
                        <span>
                            {lastCheckInDate}
                        </span>
                        <span>
                            {lastCheckInTime}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}
