//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames                     from 'classnames';
import _                              from 'lodash';
import queryString                    from 'query-string';
import { Link as ReactRouterDomLink } from 'react-router-dom';

import PropTypes       from '@components/PropTypes';
import ComponentHelper from '@helper/ComponentHelper';
import RouteHelper     from '@helper/Route';

import styles from './styles.module.scss';

export default class Link extends React.Component {
    static propTypes = {
        children:        PropTypes.children,
        className:       PropTypes.string,
        hash:            PropTypes.string,
        onClick:         PropTypes.func,
        search:          PropTypes.object,
        stopPropagation: PropTypes.bool,
        target:          PropTypes.string,
        to:              PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
    };

    static defaultProps = {
        children:        [],
        className:       '',
        hash:            '',
        onClick:         null,
        search:          {},
        stopPropagation: true,
        target:          null,
        to:              null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    getToParameter = () => {
        let searchParameters = {};
        let pathname         = this.props.to;

        if (typeof this.props.to === 'object') {
            pathname         = _.get(this.props, 'to.route', '');
            const parameters = _.get(this.props, 'to.parameters', {});
            searchParameters = queryString.parse(_.get(this.props, 'to.search', {}));
            pathname         = RouteHelper.replaceParametersInUrl(pathname, parameters);
        }

        const search = Object.entries({
            ...this.props.search,
            ...searchParameters,
        }).map(([key, value]) => `${key}=${value}`).join('&');

        const hash         = this.props.hash;
        const toParameters = {
            pathname,
            search: search ? `?${search}` : '',
            hash:   hash ? `#${hash}` : '',
        };

        return toParameters;
    };

    render() {
        const { children, className, target } = this.props;

        return (
            <ReactRouterDomLink
                className={classNames(styles.link, className)}
                to={this.getToParameter()}
                target={target}
                onClick={this.stopPropagationAndExecuteOnClick}
            >
                {children}
            </ReactRouterDomLink>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }

    stopPropagationAndExecuteOnClick = (event) => {
        const { stopPropagation, onClick } = this.props;

        if (stopPropagation) {
            event.stopPropagation();
        }

        if (onClick) {
            onClick(event);
        }
    };
}
