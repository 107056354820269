//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

export default class ComponentHelper {
    static checkPropsChangedOnComponentDidUpdate(props, prevProps, fieldOrFields = []) {
        let fields = fieldOrFields;

        if (!_.isArray(fields)) {
            fields = [fields];
        }

        const propsChanged = _.some(
            fields,
            (field) => prevProps[field] !== props[field],
        );

        return propsChanged;
    }

    static shouldComponentUpdate(componentClass, nextProps, nextStates) {
        const component = componentClass.constructor;

        if (!this.hasComponentRequiredFields(component)) {
            return true;
        }

        const props        = component.renderAffectingProps;
        const currentProps = componentClass.props;
        const propsChanged = props.some((prop) => {
            return currentProps[prop] !== nextProps[prop];
        });

        if (propsChanged) {
            return true;
        }

        const states        = component.renderAffectingStates;
        const currentStates = componentClass.state;

        if (currentStates === nextStates) {
            return false;
        }

        const statesChanged = _.some(
            states,
            (state) => currentStates[state] !== nextStates[state],
        );

        return statesChanged;
    }

    static hasComponentRequiredFields(component) {
        const hasRenderAffectingProps  = Object.prototype.hasOwnProperty.call(component, 'renderAffectingProps');
        const hasRenderAffectingStates = Object.prototype.hasOwnProperty.call(component, 'renderAffectingStates');

        if (!hasRenderAffectingProps || !hasRenderAffectingStates) {
            console.error(`The component "${component.name}" is missing the ${
                !hasRenderAffectingProps ? 'renderAffectingProps' : 'renderAffectingStates'} property.`);

            return false;
        }

        const renderAffectingPropsIsArray  = Array.isArray(component.renderAffectingProps);
        const renderAffectingStatesIsArray = Array.isArray(component.renderAffectingStates);

        if (!renderAffectingPropsIsArray || !renderAffectingStatesIsArray) {
            console.error(`Wrong variable type detected in the "${component.name}" component: The ${
                !renderAffectingPropsIsArray ? 'renderAffectingProps' : 'renderAffectingStates'} should be an "array", "${
                !renderAffectingPropsIsArray ? typeof component.renderAffectingProps : typeof component.renderAffectingStates}" given.`);

            return false;
        }

        return true;
    }

    static mapChildren(children, dynamicProps, callback) {
        let childCallback = callback;

        if (!childCallback) {
            childCallback = (child) => child;
        }

        return React.Children.map(
            children,
            (child, index) => {
                const clonedChild = React.cloneElement(
                    child,
                    {
                        ...dynamicProps(index),
                    },
                );

                return childCallback(clonedChild);
            },
        );
    }
}
