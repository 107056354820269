//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';

import classNames      from 'classnames';

import PropTypes       from '@components/PropTypes';
import ComponentHelper from '@helper/ComponentHelper';
import SelectionHelper from '@helper/SelectionHelper';
import Icon            from '@stateless/atomic/Icon';
import IconType        from '@stateless/atomic/Icon/IconType';
import IconButton      from '@stateless/atomic/IconButton';

import AlertBoxType    from './AlertBoxType';
import styles          from './styles.module.scss';

export class AlertBox extends React.Component {
    static propTypes = {
        onCloseButtonClick: PropTypes.func,
        text:               PropTypes.string,
        type:               PropTypes.oneOfObjectValues(AlertBoxType),
    };

    static defaultProps = {
        onCloseButtonClick: null,
        text:               null,
        type:               null,
    };

    static renderAffectingProps = [
        'text',
        'type',
    ];

    static renderAffectingStates = [];

    render() {
        return (
            <div
                className={classNames(
                    styles.alertBox,
                    SelectionHelper.get(
                        this.props.type,
                        {
                            [AlertBoxType.error]:   styles.alertBoxError,
                            [AlertBoxType.success]: styles.alertBoxSuccess,
                        },
                    ),
                )}
            >
                <div className={styles.alertBoxIcon}>
                    {this.renderIconForType()}
                </div>
                {this.props.text}
                {this.renderCloseButton()}
            </div>
        );
    }

    renderCloseButton = () => {
        const onCloseButtonClick = this.props.onCloseButtonClick;

        if (onCloseButtonClick) {
            return (
                <span className={styles.alertBoxCloseButtonWrapper}>
                    <IconButton
                        iconType={IconType.cross}
                        onClick={onCloseButtonClick}
                    />
                </span>
            );
        }

        return null;
    };

    renderIconForType = () => {
        const iconType = SelectionHelper.get(
            this.props.type,
            {
                [AlertBoxType.error]:   IconType.cross,
                [AlertBoxType.success]: IconType.check,
            },
        );

        return (
            <Icon
                iconType={iconType}
            />
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default AlertBox;
