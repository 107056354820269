//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }            from 'redux-saga/effects';

import { LoadingActions } from '@actions/loading';
import SagaStateHelper    from '@helper/SagaStateHelper';

function* overlayClicked() {
    const clickCount = yield SagaStateHelper.selectFromState('loading', 'clickCount');

    if (clickCount > 10) {
        yield put(LoadingActions.resetOverlay());
    }
}

export default {
    overlayClicked,
};
