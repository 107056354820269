//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import QueryString from 'query-string';

class MailHelper {
    /**
     * Opens the mail window
     *
     * @param recipientAddress
     * @param subject
     * @param body
     * @param carbonAddress
     * @param blindCarbonAddress
     */
    static openMailWindow(recipientAddress, subject, body, carbonAddress, blindCarbonAddress) {
        if (recipientAddress) {
            const url            = `mailto:${recipientAddress}?`;
            const params         = QueryString.stringify({
                subject,
                body,
                cc:  carbonAddress,
                bcc: blindCarbonAddress,
            });
            window.location.href = url + params;
        } else {
            console.warn('No recipient address set - cannot open mail window');
        }
    }
}

export default MailHelper;
