//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes         from '@/components/PropTypes';
import CenteredContainer from '@/components/stateless/atomic/CenteredContainer';
import Logo              from '@/components/stateless/atomic/Logo';
import Title             from '@/components/stateless/atomic/Title';
import ComponentHelper   from '@helper/ComponentHelper';

import styles from './styles.module.scss';

export class CenteredContainerWithLogoAndTitle extends React.Component {
    static propTypes = {
        children: PropTypes.children,
        title:    PropTypes.string,
    };

    static defaultProps = {
        children: null,
        title:    null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    renderLogo = () => {
        return (
            <div className={styles.logoContainer}>
                <Logo />
            </div>
        );
    };

    renderTitle = () => {
        return (
            <div className={styles.titleContainer}>
                <Title text={this.props.title} />
            </div>
        );
    };

    render() {
        return (
            <CenteredContainer>
                {this.renderLogo()}
                {this.renderTitle()}
                {this.props.children}
            </CenteredContainer>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default CenteredContainerWithLogoAndTitle;
