//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import LoadingLevel from '@/constants/LoadingLevel';

/**
 *
 * @param type
 * @param loadingLevel
 * @param props
 * @returns {function(...[*]): {type: *}}
 */
export function makeActionCreator(type, props = {}, loadingLevel = undefined) {
    const action = {
        type,
        loadingLevel,
        ...props,
    };

    return (args) => {
        if (args) {
            return {
                ...action,
                ...args,
            };
        }

        return action;
    };
}

/**
 *
 * @param type
 * @param props
 * @returns {function(...[*]): {type: *}}
 */
export function makeActionCreatorIncreaseLoading(type, props = {}) {
    return makeActionCreator(type, props, LoadingLevel.increase);
}

/**
 *
 * @param type
 * @param props
 * @returns {function(...[*]): {type: *}}
 */
export function makeActionCreatorDecreaseLoading(type, props = {}) {
    return makeActionCreator(type, props, LoadingLevel.decrease);
}
