//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator }                from '@helper/Store';
import { makeActionCreatorIncreaseLoading } from '@helper/Store';
import { makeActionCreatorDecreaseLoading } from '@helper/Store';

const UserTypes = Object.freeze({
    LOGIN:             'User/LOGIN',
    LOGIN_FAILED:      'User/LOGIN_FAILED',
    LOGIN_SUCCEEDED:   'User/LOGIN_SUCCEEDED',
    LOGOUT:            'User/LOGOUT',
    SET_PASSWORD:      'User/SET_PASSWORD',
    SET_REMEMBER_ME:   'User/SET_REMEMBER_ME',
    SET_USERNAME:      'User/SET_USERNAME',
    TRY_RESTORE_TOKEN: 'User/TRY_RESTORE_TOKEN',
});

const login = makeActionCreatorIncreaseLoading(
    UserTypes.LOGIN,
    {},
);

const loginFailed = makeActionCreatorDecreaseLoading(
    UserTypes.LOGIN_FAILED,
    {},
);

const loginSucceeded = makeActionCreatorDecreaseLoading(
    UserTypes.LOGIN_SUCCEEDED,
    {
        lastLogin: null,
        token:     null,
        username:  null,
    },
);

const logout = makeActionCreator(
    UserTypes.LOGOUT,
    {},
);

const setPassword = makeActionCreator(
    UserTypes.SET_PASSWORD,
    {
        password: null,
    },
);

const setUsername = makeActionCreator(
    UserTypes.SET_USERNAME,
    {
        username: null,
    },
);

const setRememberMe = makeActionCreator(
    UserTypes.SET_REMEMBER_ME,
    {
        rememberMe: null,
    },
);

const tryRestoreToken = makeActionCreator(UserTypes.TRY_RESTORE_TOKEN);

const UserActions = Object.freeze({
    login,
    loginFailed,
    loginSucceeded,
    logout,
    setPassword,
    setRememberMe,
    setUsername,
    tryRestoreToken,
});

export {
    UserActions,
    UserTypes,
};
