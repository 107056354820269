//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import 'core-js';
import '@language/Language';
import React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import ReactDOM            from 'react-dom';
import { Provider }        from 'react-redux';
import { PersistGate }     from 'redux-persist/integration/react';

import MainRouter       from '@connected/MainRouter';
import CacheInvalidator from '@stateless/atomic/CacheInvalidator';
import { history }      from '@store';
import createStore      from '@store';

import * as serviceWorker from './serviceWorker';
import styles             from './styles.module.scss';

import './sentry';
import '@store/miscellaneous/immutability-helper-extensions';

const { store, persistor } = createStore();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate
            persistor={persistor}
            loading={null}
        >
            <CacheInvalidator />
            {/*
                If you don't want to automatically clear the browsers cache and update the
                application you can switch <CacheInvalidator /> by <CacheInvalidatorWithPrompt />
                to ask the user if he wants to update.
            */}
            <ConnectedRouter history={history}>
                <div className={styles.appContent}>
                    <MainRouter />
                </div>
            </ConnectedRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);

serviceWorker.register();
