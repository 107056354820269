//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n   from 'i18next';
import _      from 'lodash';
import moment from 'moment';

import Api          from '@/constants/Api';
import CheckInState from '@/constants/CheckInState';
import Address      from '@/helper/Address';
import Hydra        from '@/helper/Hydra';

const mapUserDataWithLatestCheckin = (user, checkIn) => {
    const lastCheckIn                                                               = _.defaultTo(checkIn, _.get(user, 'lastCheckIn'));
    const createdAt                                                                 = _.get(lastCheckIn, 'createdAt');
    const { image, firstname, lastname, email, activeSubscription, customerNumber } = user;
    const userIri                                                                   = _.get(user, 'iri');
    const userId                                                                    = Hydra.getIdFromIri(userIri);
    const subscription                                                              = _.get(activeSubscription, 'subscription.name');
    const name                                                                      = `${firstname} ${lastname}`;
    const address                                                                   = Address.getAddressStringFromObject(user.address);
    const lastCheckInDateTime                                                       = createdAt ? moment(new Date(createdAt)) : false;
    const checkedIn                                                                 = _.get(user, 'checkedIn', false);
    const lastCheckInTime                                                           = lastCheckInDateTime ? lastCheckInDateTime.format(I18n.t('checkInTimeFormat')) : '-';
    const lastCheckInDate                                                           = lastCheckInDateTime ? lastCheckInDateTime.format(I18n.t('checkInDateFormat')) : '-';
    const checkInState                                                              = checkedIn ? CheckInState.CHECKED_IN : CheckInState.NOT_CHECKED_IN;
    const imagePath                                                                 = Api.getBackendUrl() + _.get(image, 'path');

    return {
        ...lastCheckIn,
        address,
        checkInState,
        checkedIn,
        customerNumber,
        email,
        image: imagePath,
        lastCheckInDate,
        lastCheckInTime,
        name,
        subscription,
        userId,
        userIri,
    };
};

export default {
    mapUserDataWithLatestCheckin,
};
