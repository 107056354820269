//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames    from 'classnames';
import I18n          from 'i18next';
import _             from 'lodash';
import DebounceInput from 'react-debounce-input';
import NumberFormat  from 'react-number-format';

import PropTypes              from '@components/PropTypes';
import ComponentHelper        from '@helper/ComponentHelper';
import NumberFormatHelper     from '@helper/NumberFormat';
import Icon                   from '@stateless/atomic/Icon';
import TextInputMode          from '@stateless/atomic/TextInput/TextInputMode';
import TextInputStyle         from '@stateless/atomic/TextInput/TextInputSize';
import TextInputTextAlignment from '@stateless/atomic/TextInput/TextInputTextAlignment';
import TextInputType          from '@stateless/atomic/TextInput/TextInputType';

import styles from './styles.module.scss';

export default class TextInput extends React.Component {
    static defaultStates = {
        updatedValue: '',
        value:        '',
    };

    static propTypes = {
        classname:       PropTypes.string,
        disabled:        PropTypes.bool,
        minLength:       PropTypes.number,
        mode:            PropTypes.oneOfObjectValues(TextInputMode),
        multiline:       PropTypes.bool,
        onBlur:          PropTypes.func,
        onChange:        PropTypes.func,
        onFocus:         PropTypes.func,
        onKeyDown:       PropTypes.func,
        placeholderText: PropTypes.string,
        postIconType:    PropTypes.iconType,
        preIconType:     PropTypes.iconType,
        size:            PropTypes.oneOfObjectValues(TextInputStyle),
        textAlignment:   PropTypes.oneOfObjectValues(TextInputTextAlignment),
        type:            PropTypes.string,
        value:           PropTypes.date,
    };

    static defaultProps = {
        classname:       null,
        disabled:        false,
        minLength:       3,
        mode:            TextInputMode.text,
        multiline:       false,
        onBlur:          _.noop,
        onChange:        _.noop,
        onFocus:         _.noop,
        onKeyDown:       _.noop,
        placeholderText: null,
        postIconType:    null,
        preIconType:     null,
        size:            TextInputStyle.default,
        textAlignment:   TextInputTextAlignment.left,
        type:            TextInputType.text,
        value:           '',
    };

    static renderAffectingProps = [
        'disabled',
        'minLength',
        'multiline',
        'placeholderText',
        'textAlignment',
        'type',
        'value',
    ];

    static renderAffectingStates = [
        'value',
    ];

    constructor(props) {
        super(props);

        this.inputReference     = React.createRef();
        this.selection          = {
            start: false,
            end:   false,
        };
        this.allowUpdateTimeout = null;
        this.state              = {
            ...TextInput.defaultStates,
        };
    }

    componentDidMount() {
        this.updateValue(this.props.value);
    }

    updateValue = (value) => {
        this.updateStateField('value', value);
    };

    updateStateField = (field, value) => {
        this.setState({
            [field]: _.defaultTo(value, ''),
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const value        = this.props.value;
        const valueChanged = value !== prevProps.value;

        if (valueChanged) {
            this.updateStateField('updatedValue', value);

            if (!this.allowUpdateTimeout) {
                this.updateValue(value);
            }
        }
    }

    onKeyDown = (event) => {
        if (this.allowUpdateTimeout) {
            clearTimeout(this.allowUpdateTimeout);
        }

        this.props.onKeyDown(event);

        this.allowUpdateTimeout = setTimeout(
            () => {
                const { value, updatedValue } = this.state;

                // This is the only way to trigger a re-render of the component
                if (value === updatedValue) {
                    const rerenderValue = (
                        this.modeIsCurrency() ?
                            0 :
                            '...'
                    );

                    this.updateValue(rerenderValue);
                }

                this.updateValue(updatedValue);

                this.allowUpdateTimeout = null;
            },
            1500,
        );
    };

    fixCursorPosition() {
        const current                          = this.inputReference.current;
        const { selectionStart, selectionEnd } = current;
        const selection                        = this.selection;
        const startSelectionChanged            = selection.start !== false && selection.start !== selectionStart;
        const endSelectionChanged              = selection.end !== false && selection.end !== selectionEnd;
        const overwriteCursorPosition          = startSelectionChanged || endSelectionChanged;

        if (overwriteCursorPosition) {
            current.selectionStart = selection.start;
            current.selectionEnd   = selection.end;
        }
    }

    getInputClassNames = () => {
        const multiline       = this.props.multiline;
        const inputClassNames = classNames(
            styles.textInput,
            {
                [styles.textInputDisabled]:     this.props.disabled,
                [styles.textInputMultiLine]:    multiline,
                [styles.textInputSingleLine]:   !multiline,
                [styles.textInputWithPostIcon]: this.props.postIconType,
                [styles.textInputWithPreIcon]:  this.props.preIconType,
            },
        );

        return inputClassNames;
    };

    render() {
        const textAlignment = this.props.textAlignment;

        return (
            <div
                className={classNames(
                    styles.textInputWrapper,
                    this.props.classname,
                    {
                        [styles.textInputWrapperSmall]:              this.props.size === TextInputStyle.small,
                        [styles.textInputWrapperTextAlignmentLeft]:  textAlignment === TextInputTextAlignment.left,
                        [styles.textInputWrapperTextAlignmentRight]: textAlignment === TextInputTextAlignment.right,
                    },
                )}
            >
                {this.renderIcon(this.props.preIconType)}
                {this.renderInput()}
                {this.renderIcon(this.props.postIconType)}
            </div>
        );
    }

    renderInput = () => {
        if (this.modeIsCurrency()) {
            return this.renderInputModeCurrency();
        }

        return this.renderInputModeText();
    };

    renderInputModeCurrency = () => {
        const className = this.getInputClassNames();
        let { value }   = this.props;

        if (
            value &&
            this.modeIsCurrency()
        ) {
            value = NumberFormatHelper.fromEuroCent(value);
        }

        return (
            <NumberFormat
                className={className}
                element={'input'}
                debounceTimeout={500}
                disabled={this.props.disabled}
                minLength={this.props.minLength}
                onBlur={this.props.onBlur}
                onFocus={this.props.onFocus}
                onChange={this.onChangeModeCurrency}
                onKeyDown={this.onKeyDown}
                placeholder={this.props.placeholderText}
                allowNegative={true}
                decimalSeparator={I18n.t('decimalSeparator')}
                displayType={'input'}
                isNumericString={true}
                customInput={DebounceInput}
                suffix={I18n.t('euroSign')}
                thousandSeparator={I18n.t('thousandSeparator')}
                value={value}
                inputRef={this.inputReference}
            />
        );
    };

    renderInputModeText = () => {
        const element    = (
            this.props.multiline ?
                'textarea' :
                'input'
        );
        const className  = this.getInputClassNames();
        const stateValue = this.state.value;
        const value      = _.isNil(stateValue) ? '' : stateValue;

        return (
            <DebounceInput
                className={className}
                element={element}
                debounceTimeout={500}
                disabled={this.props.disabled}
                minLength={this.props.minLength}
                onBlur={this.props.onBlur}
                onFocus={this.props.onFocus}
                onChange={this.valueChanged}
                onKeyDown={this.onKeyDown}
                placeholder={this.props.placeholderText}
                value={value}
                type={this.props.type}
                inputRef={this.inputReference}
            />
        );
    };

    renderIcon(iconType, postIcon = false) {
        if (iconType) {
            return (
                <div
                    className={classNames(
                        styles.iconWrapper,
                        {
                            [styles.iconWrapperPre]: !postIcon,
                        },
                    )}
                >
                    <Icon iconType={iconType} />
                </div>
            );
        }

        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.fixCursorPosition();

        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }

    modeIsCurrency = () => {
        return this.props.mode === TextInputMode.currency;
    };

    onChangeModeCurrency = (event) => {
        const props = this.props;

        if (this.modeIsCurrency()) {
            const value    = _.get(event, ['target', 'value']);
            let toEuroCent = NumberFormatHelper.toEuroCent(value);

            if (_.isNil(value) || _.isEmpty(value)) {
                toEuroCent = 0;
            }

            props.onChange(toEuroCent);

            return;
        }

        props.onChange(event);
    };

    valueChanged = (event) => {
        const input    = this.inputReference.current;
        this.selection = {
            start: input.selectionStart,
            end:   input.selectionEnd,
        };

        this.props.onChange(event);
    };
}
