//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update from 'immutability-helper';

update.extend('$decreaseBy', (amount, value) => {
    return value - amount;
});

update.extend('$decreaseByStayPositive', (amount, value) => {
    if (value > 1) {
        return value - amount;
    }

    return value;
});

update.extend('$increaseBy', (amount, value) => {
    return value + amount;
});

update.extend('$removeArrayItemAtIndex', (index, value) => {
    if (index >= 0) {
        const valueCopy = [...value];

        valueCopy.splice(index, 1);

        return valueCopy;
    }

    return value;
});
