//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import AlertBoxManager                   from '@/components/connected/AlertBoxManager';
import CheckBox                          from '@/components/stateless/atomic/CheckBox';
import CustomButton                      from '@/components/stateless/atomic/CustomButton';
import TextInputType                     from '@/components/stateless/atomic/TextInput/TextInputType';
import CenteredContainerWithLogoAndTitle from '@/components/stateless/composed/CenteredContainerWithLogoAndTitle';
import InputWithLabel                    from '@/components/stateless/composed/InputWithLabel';
import MailToLink                        from '@/components/stateless/composed/MailToLink';
import ComponentHelper                   from '@/helper/ComponentHelper';
import { AlertBoxActions }               from '@actions/alertBox';
import { UserActions }                   from '@actions/user';
import PropTypes                         from '@components/PropTypes';

import styles from './styles.module.scss';

const mapStateToProps = (state) => {
    return {
        password:   _.get(state, 'user.password'),
        rememberMe: _.get(state, 'user.rememberMe'),
        token:      _.get(state, 'user.token'),
        username:   _.get(state, 'user.username'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...AlertBoxActions,
            ...UserActions,
        },
        dispatch,
    );
};

class Login extends React.Component {
    static propTypes = {
        clearAlerts:   PropTypes.func,
        login:         PropTypes.func,
        password:      PropTypes.string,
        rememberMe:    PropTypes.bool,
        setPassword:   PropTypes.func,
        setRememberMe: PropTypes.func,
        setUsername:   PropTypes.func,
        username:      PropTypes.string,
    };

    static defaultProps = {
        clearAlerts:   _.noop,
        login:         _.noop,
        password:      null,
        rememberMe:    false,
        setPassword:   _.noop,
        setRememberMe: _.noop,
        setUsername:   _.noop,
        username:      null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    login = () => {
        this.props.clearAlerts();
        this.props.login();
    };

    toggleRememberMe = () => {
        this.props.setRememberMe({
            rememberMe: !this.props.rememberMe,
        });
    };

    usernameChanged = (event) => {
        this.props.setUsername({
            username: event.target.value,
        });
    };

    passwordChanged = (event) => {
        this.props.setPassword({
            password: event.target.value,
        });
    };

    renderInput = () => {
        const { username, password, rememberMe } = this.props;

        return (
            <div className={styles.inputContainer}>
                <InputWithLabel
                    label={I18n.t('email')}
                    value={username}
                    onInputChange={this.usernameChanged}
                />
                <InputWithLabel
                    label={I18n.t('password')}
                    value={password}
                    type={TextInputType.password}
                    onInputChange={this.passwordChanged}
                />
                <CheckBox
                    checked={rememberMe}
                    valueChanged={this.toggleRememberMe}
                    text={I18n.t('rememberMe')}
                />
            </div>
        );
    };

    renderLoginConfirmation = () => {
        return (
            <div>
                <CustomButton
                    fullWidth={true}
                    onClick={this.login}
                >
                    {I18n.t('continue')}
                </CustomButton>
            </div>
        );
    };

    renderForgotPassword = () => {
        const emailToSendPasswordResetRequestTo = I18n.t('emailToSendPasswordResetRequestTo');

        return (
            <div className={styles.forgotPasswordContainer}>
                {I18n.t('forgotPasswordQuestion')}
                <MailToLink
                    className={styles.forgotPasswordLink}
                    email={emailToSendPasswordResetRequestTo}
                    subject={I18n.t('forgotPasswordSubject')}
                    body={I18n.t('forgotPasswordBody')}
                >
                    {I18n.t('resetPassword')}
                </MailToLink>
            </div>
        );
    };

    render() {
        return (
            <>
                <CenteredContainerWithLogoAndTitle title={I18n.t('loginTitle')}>
                    <AlertBoxManager />
                    {this.renderInput()}
                    {this.renderLoginConfirmation()}
                    {this.renderForgotPassword()}
                </CenteredContainerWithLogoAndTitle>
            </>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Login);
