//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import PropTypes       from '@components/PropTypes';
import ComponentHelper from '@helper/ComponentHelper';

export default class UserCheckInHeader extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <div className={this.props.className}>
                <div>
                </div>
                <div>
                    {I18n.t('name')}
                </div>
                <div>
                    {I18n.t('email')}
                </div>
                <div>
                    {I18n.t('address')}
                </div>
                <div>
                    {I18n.t('subscriptionState')}
                </div>
                <div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}
