//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';

import Label           from '@/components/stateless/atomic/Label';
import TextInput       from '@/components/stateless/atomic/TextInput';
import TextInputType   from '@/components/stateless/atomic/TextInput/TextInputType';
import PropTypes       from '@components/PropTypes';
import ComponentHelper from '@helper/ComponentHelper';

export default class InputWithLabel extends React.Component {
    static propTypes = {
        label:         PropTypes.string,
        onInputChange: PropTypes.func,
        type:          PropTypes.oneOfObjectValues(TextInputType),
        value:         PropTypes.string,
    };

    static defaultProps = {
        label:         null,
        onInputChange: _.noop,
        type:          TextInputType.text,
        value:         null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        const { label, value, type, onInputChange } = this.props;

        return (
            <div>
                <Label value={label} />
                <TextInput
                    type={type}
                    value={value}
                    onChange={onInputChange}
                />
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}
