//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import AvatarStateCircleTheme from '@/components/stateless/atomic/AvatarStateCircle/AvatarStateCircleTheme';
import PropTypes              from '@components/PropTypes';
import ComponentHelper        from '@helper/ComponentHelper';

import styles from './styles.module.scss';

export class AvatarStateCircle extends React.Component {
    static propTypes = {
        children: PropTypes.children,
        theme:    PropTypes.string,
    };

    static defaultProps = {
        children: null,
        theme:    AvatarStateCircleTheme.green,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        const { children, theme } = this.props;

        return (
            <div
                className={classNames(
                    styles.circleContainer,
                    {
                        [styles.circleContainerGreen]: theme === AvatarStateCircleTheme.green,
                        [styles.circleContainerRed]:   theme === AvatarStateCircleTheme.red,
                    },
                )}
            >
                <div className={styles.circle} />
                <div className={styles.circle} />
                <div className={styles.circle} />
                {children}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default AvatarStateCircle;
