//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';
import _    from 'lodash';

import PropTypes       from '@/components/PropTypes';
import CustomButton    from '@/components/stateless/atomic/CustomButton';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import IconButton      from '@/components/stateless/atomic/IconButton';
import Logo            from '@/components/stateless/atomic/Logo';
import TextInput       from '@/components/stateless/atomic/TextInput';
import TextInputType   from '@/components/stateless/atomic/TextInput/TextInputType';
import ComponentHelper from '@helper/ComponentHelper';

import styles from './styles.module.scss';

export default class Header extends React.Component {
    static propTypes = {
        loadingIndicator:    PropTypes.elementType,
        onInputChange:       PropTypes.func,
        onLogoClick:         PropTypes.func,
        onLogoutButtonClick: PropTypes.func,
        onSearchButtonClick: PropTypes.func,
        value:               PropTypes.string,
    };

    static defaultProps = {
        loadingIndicator:    null,
        onInputChange:       _.noop,
        onLogoClick:         _.noop,
        onLogoutButtonClick: _.noop,
        onSearchButtonClick: _.noop,
        value:               null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    onSearchButtonClick = () => {
        this.props.onSearchButtonClick();
    };

    onLogoutButtonClick = () => {
        this.props.onLogoutButtonClick();
    };

    onKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.props.onSearchButtonClick(event.target.value);
        }
    };

    render() {
        return (
            <div className={styles.headerContainer}>
                <div className={styles.logoutButtonContainer}>
                    <Logo onClick={this.props.onLogoClick} />
                    <IconButton
                        className={styles.logoutButton}
                        iconType={IconType.cross}
                        onClick={this.onLogoutButtonClick}
                    />
                </div>
                <div className={styles.searchContainer}>
                    {this.props.loadingIndicator}
                    <TextInput
                        preIconType={IconType.search}
                        classname={styles.searchInput}
                        type={TextInputType.text}
                        placeholderText={I18n.t('searchPlaceholder')}
                        onChange={this.props.onInputChange}
                        value={this.props.value}
                        onKeyDown={this.onKeyPress}
                    />
                    <CustomButton
                        classname={styles.searchButton}
                        onClick={this.onSearchButtonClick}
                    >
                        {I18n.t('search')}
                    </CustomButton>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(this, nextProps, nextState);
    }
}
