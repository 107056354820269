//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _            from 'lodash';
import { connect }  from 'react-redux';
import { Route }    from 'react-router';
import { Switch }   from 'react-router';
import { Redirect } from 'react-router';

import Routes          from '@/constants/Routes';
import Dashboard       from '@/screens/Dashboard';
import Login           from '@/screens/Login';
import NotFound        from '@/screens/NotFound';
import PropTypes       from '@components/PropTypes';
import ComponentHelper from '@helper/ComponentHelper';

const mapStateToProps = (state) => (
    {
        token: _.get(state, ['user', 'token']),
    }
);

class MainRouter extends React.Component {
    static propTypes = {
        token: PropTypes.string,
    };

    static defaultProps = {
        token: null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    renderLogin = () => {
        if (this.props.token) {
            return (
                <Redirect to={Routes.home} />
            );
        }

        return (
            <Login />
        );
    };

    render() {
        return (
            <Switch>
                <Route
                    exact
                    path={Routes.home}
                    component={Dashboard}
                />
                <Route
                    exact
                    path={Routes.userInfo}
                    component={Dashboard}
                />
                <Route
                    exact
                    path={Routes.search}
                    component={Dashboard}
                />
                <Route
                    exact
                    path={Routes.login}
                    component={Login}
                >
                    {this.renderLogin()}
                </Route>
                <Route component={NotFound} />
            </Switch>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default connect(
    mapStateToProps,
    null,
)(MainRouter);
