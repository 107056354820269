//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import CustomButtonTheme from '@/components/stateless/atomic/CustomButton/CustomButtonTheme';
import PropTypes         from '@components/PropTypes';
import ComponentHelper   from '@helper/ComponentHelper';

import styles from './styles.module.scss';

export class CustomButton extends React.Component {
    static propTypes = {
        children:  PropTypes.children,
        classname: PropTypes.string,
        fullWidth: PropTypes.bool,
        onClick:   PropTypes.func,
        theme:     PropTypes.oneOfObjectValues(CustomButtonTheme),
    };

    static defaultProps = {
        children:  null,
        classname: null,
        fullWidth: false,
        onClick:   _.noop,
        theme:     CustomButtonTheme.default,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = Object.keys(this.defaultProps);

    render() {
        const { fullWidth, classname, theme, onClick, children } = this.props;

        return (
            <button
                type={'button'}
                onClick={onClick}
                className={classNames(
                    styles.button,
                    classname,
                    {
                        [styles.fullWidth]:             fullWidth,
                        [styles.transparentThinBorder]: theme === CustomButtonTheme.transparentThinBorder,
                    },
                )}
            >
                {children}
            </button>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default CustomButton;
