//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const TestIds = Object.freeze({
    exampleHeaderButton:      'example-header-button',
    homeHeaderButton:         'home-header-button',
    imprintHeaderButton:      'imprint-header-button',
    inventoryHeaderButton:    'inventory-header-button',
    loginHeaderButton:        'login-header-button',
    screenDesignHeaderButton: 'screen-design-header-button',
    smartUpdaterHeaderButton: 'smart-updater-header-button',
});

const TestIdPrefixes = Object.freeze({
    inventoryItem: 'inventory-item-',
});

export {
    TestIds,
    TestIdPrefixes,
};
