//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update from 'immutability-helper';

import { UserTypes }    from '@/store/actions/user';
import { CheckInTypes } from '@actions/checkIn';

const initialState = Object.freeze({
    checkIns: [],
});

const fetchCheckInsSucceeded = (action, state) => {
    return update(state, {
        checkIns: {
            $set: action.checkIns,
        },
    });
};

const logout = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case CheckInTypes.FETCH_CHECK_INS_SUCCEEDED: return fetchCheckInsSucceeded(action, state);
        case UserTypes.LOGOUT:                       return logout(action, state);
        default:                                     return state;
        // @formatter:on
    }
}
