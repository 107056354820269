//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const german = Object.freeze({
    address:                           'Adresse',
    alertBoxErrorText:                 'Fehler',
    alertBoxErrorTextTranslated:       'Fehler (automatisch übersetzt)',
    alertBoxSuccessText:               'Erfolg',
    alertBoxSuccessTextTranslated:     'Erfolg (automatisch übersetzt)',
    back:                              'Zurück',
    bookmarkBannerText:                'Tip: Fügen Sie diese Anwendung als Lesezeichen hinzu, um sie offline verwenden zu können.',
    checkedIn:                         'Check-in erfolgreich',
    checkIn:                           'Check-in:',
    checkInDateFormat:                 'DD.MM.YYYY',
    checkInFailed:                     'Check-in fehlgeschlagen',
    checkInFailedExplanation:          'Der User hat für seinen aktuellen Abrechnungsmonat keine Check-ins mehr übrig.',
    checkInNow:                        'Einchecken',
    checkInTimeFormat:                 'HH:mm',
    continue:                          'Weiter',
    countDownDay:                      'Tag',
    countDownDay_plural:               'Tage',
    countDownHour:                     'Stunde',
    countDownHours:                    'Stunden',
    countDownMinute:                   'Minute',
    countDownMinutes:                  'Minuten',
    countDownSecond:                   'Sekunde',
    countDownSeconds:                  'Sekunden',
    customerNumber:                    'Kundennummer',
    email:                             'E-Mail',
    emailToSendPasswordResetRequestTo: 'info@berlinbouldert.de', // TODO https://lulububu.atlassian.net/browse/BERBOUKOL-203
    error_404:                         '404',
    exampleAlertBox:                   'Beispiel-Alertbox',
    exampleBreadCrumbBar:              'Beispiel-Breadcrumbbar',
    exampleCheckBox:                   'Beispiel-Checkbox',
    exampleConsole:                    'Beispiel-Konsole',
    exampleCountDown:                  'Beispiel-Countdown',
    exampleDateTimeFormat:             'DD.MM.YYYY HH:mm:ss',
    exampleDebounceInput:              'Beispiel-Entprelltes-Eingabefeld',
    exampleDropdown:                   'Beispiel-Dropdown',
    exampleIcons:                      'Beispiel-Icons',
    exampleImage:                      'Beispiel-Bild',
    exampleImageStorage:               'Beispiel-Image-Storage',
    exampleKeyDown:                    'Beispiel-Tasten-Events',
    exampleKeyDownText:                'Du kannst in diesem Textfeld nicht die "Enter"-Taste benutzen:',
    exampleLabels:                     'Beispiel-Labels',
    exampleLeftRightSplitter:          'Beispiel-Left-Right-Splitter',
    exampleNormalizer:                 'Beispiel-Normalizer',
    exampleNumberFormat:               'Beispiel-Nummernformatierung',
    exampleRepeat:                     'Beispiel-Repeat',
    examples:                          'Beispiele',
    fetchUserError:                    'Fehler beim Abrufen des Nutzers',
    forgotPasswordBody:                'Ich habe mein Passwort zum Anmelden bei der Kassen-Anwendung vergessen.\n\nBitte setzen Sie das Passwort entsprechend zurück.\nDie E-Mail-Adresse zur Boulderhalle ist die folgende: ',
    forgotPasswordQuestion:            'Passwort vergessen?',
    forgotPasswordSubject:             'Passwort-Vergessen',
    home:                              'Home',
    iAmScrolled:                       'Ich scrolle mit',
    imprint:                           'Impressum',
    inventory:                         'Inventar',
    inventoryDetails:                  'Inventar-Details',
    itemNotFound:                      'Eintrag nicht gefunden',
    lastCheckIn:                       'Letzter Check-in',
    lastCheckIns:                      'Letzte Check-ins',
    loading:                           'Lade...',
    loadingConfiguration:              'Lade Server-Konfiguration...',
    loadingInventory:                  'Lade Inventarliste...',
    loadingLogin:                      'Prüfe Zugangsdaten...',
    login:                             'Login',
    loginError:                        'Login fehlgeschlagen',
    loginTitle:                        'Login',
    logout:                            'Logout',
    name:                              'Name',
    newVersionHintLaterButton:         'Later',
    newVersionHintText:                'A new version of this application is available, please update now.',
    newVersionHintUpdateButton:        'Update',
    noData:                            'Keine Daten gefunden',
    noDataExplanation:                 'Es wurden keine Daten gefunden.',
    notCheckedIn:                      'Nicht eingecheckt',
    pageNotFound:                      'Seite nicht gefunden',
    pageNotFoundMessage:               'Die Seite, die Sie aufgerufen haben, wurde nicht gefunden. Bitte prüfen Sie, ob Sie die Adresse korrekt eingegeben haben.',
    password:                          'Passwort',
    rangeCombinerFrom:                 'von',
    rangeCombinerShort:                ' - ',
    rangeCombinerTo:                   'bis',
    reloadData:                        'Daten neu laden',
    rememberMe:                        'Eingeloggt bleiben',
    resetPassword:                     'Passwort zurücksetzen',
    screenDesign:                      'Screendesign',
    screenTooSmallText:                'Ihre aktuelle Browsergröße wird derzeit nicht unterstützt. Bitte vergrößern Sie dieses Fenster.',
    screenTooSmallTitle:               'Ihr Display oder Browserfenster ist zu klein',
    searchPlaceholder:                 'E-Mail, Name, Adresse...',
    search:                            'Suchen',
    smartUpdater:                      'Smart Updater Debugger',
    smartUpdaterData:                  'Daten',
    smartUpdaterLastUpdate:            'Zuletzt aktualisiert',
    smartUpdaterReload:                'neu laden',
    smartUpdaterReloadAll:             'Alle Daten neu laden',
    smartUpdaterReset:                 'zurücksetzen',
    smartUpdaterResetAll:              'Smart Updater zurücksetzen',
    stagingPasswordPlaceholder:        'Staging-Passwort',
    subscription:                      'Abo',
    subscriptionState:                 'Abostatus',
    time:                              'Zeit',
    webAppCancel:                      'Abbrechen',
    webAppInstall:                     'Installieren',
    webAppInstallText:                 'Sie können diese Anwendung installieren, um sie auch offline verwenden zu können.',
    webAppOk:                          'OK',
    windowSize:                        'Fenstergröße',
    //
    // The following entries are mandatory
    //
    and:                     'und',
    more:                    'Mehr',
    no:                      'Nein',
    oclock:                  'Uhr',
    or:                      'oder',
    pageTitle:               'Berlin bouldert - Check-in-App',
    versionNumberDateFormat: 'DD.MM.YYYY HH:mm:ss',
    yes:                     'Ja',
    //
    // The following entries are component specific
    //
    logoAltText: 'Berlin bouldert - Check-in-App',
});

export default german;
