//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

class String {
    static decodeHTMLInString(html) {
        const text     = document.createElement('textarea');
        text.innerHTML = html;

        return text.value;
    }

    static removeHTMLFromString(text) {
        const decodedText     = String.decodeHTMLInString(text);
        const textWithoutHTML = decodedText.replace(/<[^>]*>/g, '');

        return textWithoutHTML;
    }

    static truncate(stringToTruncate, totalLength, truncatedStringEllipsis = '...') {
        if (stringToTruncate) {
            return (
                stringToTruncate.length > totalLength ?
                    stringToTruncate.substring(0, totalLength - truncatedStringEllipsis.length) + truncatedStringEllipsis
                    : stringToTruncate
            );
        }

        return stringToTruncate;
    }

    static trimAllStrings(stringArray) {
        return _.map(
            stringArray,
            _.trim,
        );
    }

    static joinNotNull(array, separator = ',') {
        return _.join(
            _.compact(array),
            separator,
        );
    }
}

export default String;
