//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                      from 'lodash';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import Routes                    from '@/constants/Routes';
import Route                     from '@/helper/Route';
import { NavigationActions }     from '@/store/actions/navigation';
import { LoadingActions }        from '@actions/loading';
import PropTypes                 from '@components/PropTypes';
import ComponentHelper           from '@helper/ComponentHelper';
import StatelessUserCheckInTable from '@stateless/composed/UserCheckInTable';

const mapStateToProps = (state) => (
    {
        checkIns: _.get(state, 'checkIn.checkIns'),
    }
);

const mapDispatchToProps = (dispatch) => (
    bindActionCreators(
        {
            ...LoadingActions,
            ...NavigationActions,
        },
        dispatch,
    )
);

class UserCheckInTable extends React.Component {
    static propTypes = {
        checkIns: PropTypes.array,
        redirect: PropTypes.func,
    };

    static defaultProps = {
        checkIns: [],
        redirect: _.noop,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    onUserInfoClick = (userId) => {
        const route = Route.buildPath(
            Routes.userInfo,
            {
                userId,
            },
        );

        this.props.redirect({
            route,
        });
    };

    render() {
        return (
            <StatelessUserCheckInTable
                checkIns={this.props.checkIns}
                onUserInfoClick={this.onUserInfoClick}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserCheckInTable);
