//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                      from 'lodash';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import CheckInState                    from '@/constants/CheckInState';
import Navigation                      from '@/helper/Navigation';
import String                          from '@/helper/String';
import { CheckInActions }              from '@/store/actions/checkIn';
import { DashboardActions }            from '@/store/actions/dashboard';
import PropTypes                       from '@components/PropTypes';
import ComponentHelper                 from '@helper/ComponentHelper';
import StatelessUserCheckInInfoOverlay from '@stateless/composed/UserCheckInInfoOverlay';

const mapStateToProps = (state, props) => (
    {
        activeCheckInInfoUser: _.get(state, 'dashboard.activeCheckInInfoUser'),
        userId:                _.get(props, 'match.params.userId'),
    }
);

const mapDispatchToProps = (dispatch) => (
    bindActionCreators(
        {
            ...CheckInActions,
            ...DashboardActions,
        },
        dispatch,
    )
);

class UserCheckInInfoOverlay extends React.Component {
    static propTypes = {
        activeCheckInInfoUser: PropTypes.object,
        checkInUser:           PropTypes.func,
        fetchUser:             PropTypes.func,
        userId:                PropTypes.string,
    };

    static defaultProps = {
        activeCheckInInfoUser: {},
        checkInUser:           _.noop,
        fetchUser:             _.noop,
        userId:                null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    constructor(props) {
        super(props);

        this.props.fetchUser();
    }

    onCloseClick = () => {
        Navigation.goBack();
    };

    onCheckInClick = () => {
        this.props.checkInUser();
    };

    render() {
        const activeCheckInInfoUser = this.props.activeCheckInInfoUser;

        if (!activeCheckInInfoUser) {
            return null;
        }

        const { name, lastCheckInDate, lastCheckInTime, image, email, customerNumber, address, subscription } = activeCheckInInfoUser;
        const boulderingHallName                                                                              = _.get(activeCheckInInfoUser, 'boulderingHall.name');
        const lastCheckIn                                                                                     = String.joinNotNull(
            [
                lastCheckInDate,
                boulderingHallName ? `(${boulderingHallName})` : null,
            ],
            ' ',
        );
        let checkInState                                                                                      = _.get(activeCheckInInfoUser, 'checkInState');
        const checkInError                                                                                    = _.get(activeCheckInInfoUser, 'checkInFailed');

        if (checkInError) {
            checkInState = CheckInState.CHECK_IN_FAILED;
        }

        return (
            <StatelessUserCheckInInfoOverlay
                avatarSource={image}
                checkInState={checkInState}
                userName={name}
                lastCheckIn={lastCheckIn}
                time={lastCheckInTime}
                address={address}
                email={email}
                subscription={subscription}
                customerNumber={customerNumber}
                onCloseClick={this.onCloseClick}
                onCheckInClick={this.onCheckInClick}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserCheckInInfoOverlay);
