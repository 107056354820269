//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes       from '@components/PropTypes';
import ComponentHelper from '@helper/ComponentHelper';
import AlertBox        from '@stateless/atomic/AlertBox';

import styles from './styles.module.scss';

export default class AlertBoxWrapper extends React.Component {
    static propTypes = {
        alertBoxes:        PropTypes.array,
        hideButtonPressed: PropTypes.func,
    };

    static defaultProps = {
        alertBoxes:        [],
        hideButtonPressed: _.noop,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <div className={styles.alertBoxWrapper}>
                {this.props.alertBoxes.map(this.renderAlertBox)}
            </div>
        );
    }

    renderAlertBox = (data, index) => {
        if (data) {
            return (
                <AlertBox
                    onCloseButtonClick={this.props.hideButtonPressed}
                    key={`alert-${index}`}
                    text={data.text}
                    type={data.type}
                />
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}
