//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n        from 'i18next';
import _           from 'lodash';
import { connect } from 'react-redux';

import PropTypes       from '@/components/PropTypes';
import Title           from '@/components/stateless/atomic/Title';
import Routes          from '@/constants/Routes';
import ComponentHelper from '@helper/ComponentHelper';

const mapStateToProps = (state) => {
    let title      = I18n.t('lastCheckIns');
    const pathname = _.get(state, 'router.location.pathname');

    if (pathname === Routes.search) {
        title = I18n.t('search');
    }

    return {
        title,
    };
};

class DashboardTitle extends React.Component {
    static propTypes = {
        title: PropTypes.string,
    };

    static defaultProps = {
        title: null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <Title text={this.props.title} />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(this, nextProps, nextState);
    }
}

export default connect(
    mapStateToProps,
    null,
)(DashboardTitle);
