//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreatorIncreaseLoading } from '@helper/Store';
import { makeActionCreatorDecreaseLoading } from '@helper/Store';
import { makeActionCreator }                from '@helper/Store';

const DashboardTypes = Object.freeze({
    EXECUTE_SEARCH:       'Dashboard/EXECUTE_SEARCH',
    FETCH_USER:           'Dashboard/FETCH_USER',
    FETCH_USER_FAILED:    'Dashboard/FETCH_USER_FAILED',
    FETCH_USER_SUCCEEDED: 'Dashboard/FETCH_USER_SUCCEEDED',
    SET_SEARCH_QUERY:     'Dashboard/SET_SEARCH_QUERY',
    SET_CHECK_IN_FAILED:  'Dashboard/SET_CHECK_IN_FAILED',
});

const fetchUser = makeActionCreatorIncreaseLoading(DashboardTypes.FETCH_USER, {
    userId: null,
});

const fetchUserFailed    = makeActionCreatorDecreaseLoading(DashboardTypes.FETCH_USER_FAILED);
const fetchUserSucceeded = makeActionCreatorDecreaseLoading(DashboardTypes.FETCH_USER_SUCCEEDED);

const executeSearch = makeActionCreator(DashboardTypes.EXECUTE_SEARCH, {
    searchQuery: undefined,
});

const setSearchQuery = makeActionCreator(DashboardTypes.SET_SEARCH_QUERY, {
    searchQuery: null,
});

const setCheckInFailed = makeActionCreator(DashboardTypes.SET_CHECK_IN_FAILED, {
    error: null,
});

const DashboardActions = Object.freeze({
    executeSearch,
    fetchUser,
    fetchUserFailed,
    fetchUserSucceeded,
    setSearchQuery,
    setCheckInFailed,
});

export {
    DashboardActions, DashboardTypes,
};
