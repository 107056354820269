//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import Account         from '@/assets/images/account.png';
import PropTypes       from '@components/PropTypes';
import ComponentHelper from '@helper/ComponentHelper';

import styles from './styles.module.scss';

export class Avatar extends React.Component {
    static propTypes = {
        alternateText: PropTypes.string,
        avatarLarge:   PropTypes.bool,
        source:        PropTypes.string,
    };

    static defaultProps = {
        alternateText: 'Avatar Image',
        avatarLarge:   false,
        source:        null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    handleImageError = (event) => {
        const target = event.target;
        target.src   = Account;
    };

    render() {
        const { alternateText, avatarLarge } = this.props;
        let source                           = _.get(this.props, 'source', false);

        if (!source) {
            source = Account;
        }

        return (
            <img
                className={classNames(
                    styles.avatar,
                    {
                        [styles.avatarLarge]: avatarLarge,
                    },
                )}
                src={source}
                onError={this.handleImageError}
                alt={alternateText}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default Avatar;
