//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreatorIncreaseLoading } from '@helper/Store';
import { makeActionCreatorDecreaseLoading } from '@helper/Store';

const CheckInTypes = Object.freeze({
    CHECK_IN_USER:             'CheckIn/CHECK_IN_USER',
    CHECK_IN_USER_FAILED:      'CheckIn/CHECK_IN_USER_FAILED',
    CHECK_IN_USER_SUCCEEDED:   'CheckIn/CHECK_IN_USER_SUCCEEDED',
    FETCH_CHECK_INS:           'CheckIn/FETCH_CHECK_INS',
    FETCH_CHECK_INS_FAILED:    'CheckIn/FETCH_CHECK_INS_FAILED',
    FETCH_CHECK_INS_SUCCEEDED: 'CheckIn/FETCH_CHECK_INS_SUCCEEDED',
});

const checkInUser       = makeActionCreatorIncreaseLoading(CheckInTypes.CHECK_IN_USER);
const checkInUserFailed = makeActionCreatorDecreaseLoading(CheckInTypes.CHECK_IN_USER_FAILED);

const checkInUserSucceeded = makeActionCreatorDecreaseLoading(CheckInTypes.CHECK_IN_USER_SUCCEEDED, {
    checkIn: null,
});

const fetchCheckIns       = makeActionCreatorIncreaseLoading(CheckInTypes.FETCH_CHECK_INS, {});
const fetchCheckInsFailed = makeActionCreatorDecreaseLoading(CheckInTypes.FETCH_CHECK_INS_FAILED, {});

const fetchCheckInsSucceeded = makeActionCreatorDecreaseLoading(CheckInTypes.FETCH_CHECK_INS_SUCCEEDED, {
    checkIns: null,
});

const CheckInActions = Object.freeze({
    checkInUser,
    checkInUserFailed,
    checkInUserSucceeded,
    fetchCheckIns,
    fetchCheckInsFailed,
    fetchCheckInsSucceeded,
});

export {
    CheckInActions,
    CheckInTypes,
};
