//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@helper/Store';

const AlertBoxTypes = Object.freeze({
    CLEAR:                   'AlertBox/CLEAR',
    CLEAR_SINGLE:            'AlertBox/CLEAR_SINGLE',
    SHOW_ERROR:              'AlertBox/SHOW_ERROR',
    SHOW_ERROR_TRANSLATED:   'AlertBox/SHOW_ERROR_TRANSLATED',
    SHOW_SUCCESS:            'AlertBox/SHOW_SUCCESS',
    SHOW_SUCCESS_TRANSLATED: 'AlertBox/SHOW_SUCCESS_TRANSLATED',
});

const clearAlerts = makeActionCreator(
    AlertBoxTypes.CLEAR,
    {},
);

const clearSingleAlert = makeActionCreator(
    AlertBoxTypes.CLEAR_SINGLE,
    {
        index: null,
    },
);

const showErrorAlert = makeActionCreator(
    AlertBoxTypes.SHOW_ERROR,
    {
        text: null,
    },
);

const showErrorAlertTranslated = makeActionCreator(
    AlertBoxTypes.SHOW_ERROR_TRANSLATED,
    {
        textKey: null,
    },
);

const showSuccessAlert = makeActionCreator(
    AlertBoxTypes.SHOW_SUCCESS,
    {
        text:        null,
        lifeCounter: -1,
    },
);

const showSuccessAlertTranslated = makeActionCreator(
    AlertBoxTypes.SHOW_SUCCESS_TRANSLATED,
    {
        textKey: null,
    },
);

const AlertBoxActions = Object.freeze({
    clearAlerts,
    clearSingleAlert,
    showErrorAlert,
    showErrorAlertTranslated,
    showSuccessAlert,
    showSuccessAlertTranslated,
});

export {
    AlertBoxActions,
    AlertBoxTypes,
};
